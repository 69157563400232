import { memo } from "react"
import Sidebar from "./Sidebar"
import { classNames, hasRole } from "../../utils/utils"
import { ScrollContext } from "../../scrollContext"
import { useAuth, useUser } from "@clerk/clerk-react"
import { Navbar } from "../navigation/Navbar"

const Layout = ({ children }) => {
  const { isSignedIn, user } = useUser()
  const { signOut } = useAuth()
  const isAdmin = hasRole(user?.publicMetadata, "admin")
  if (isSignedIn && !isAdmin) {
    signOut({
      redirectUrl: "/",
    })
  }

  return <DesktopLayout isSignedIn={isSignedIn}>{children}</DesktopLayout>
}

const DesktopLayout = ({ children, isSignedIn }) => {
  return (
    <div className="bg-white">
      <Navbar />
      <div className="relative inline-flex w-screen h-screen">
        {isSignedIn && <Sidebar isSignedIn={isSignedIn} />}
        <ScrollContext.Consumer>
          {({ elementRef }) => (
            <main
              className={classNames(
                "ml-0 w-full overflow-y-scroll rounded-t-2xl bg-gray-50 pb-20 scrollbar-hide",
              )}
              id="main"
              ref={elementRef}
            >
              <div className="mx-auto bg-transparent max-w-full">
                {children}
              </div>
            </main>
          )}
        </ScrollContext.Consumer>
      </div>
    </div>
  )
}

export default memo(Layout)
