import { createSlice, createEntityAdapter } from "@reduxjs/toolkit"

const preferencesAdapter = createEntityAdapter()
const initialState = preferencesAdapter.getInitialState({
  deviceType: localStorage.getItem("deviceType"),
  isMobile: window.innerWidth <= 640,
  isInstalled: !!window.matchMedia("(display-mode: standalone)"),
  mobileNavbarHeight: 0,
})

export const preferencesSlice = createSlice({
  name: "preferences",
  initialState,
  reducers: {
    setDeviceType: (state, action) => {
      state.deviceType = action.payload
      localStorage.setItem("deviceType", action.payload)
    },
    setIsMobile(state, action) {
      state.isMobile = action.payload
    },
    setMobileNavbarHeight(state, action) {
      state.mobileNavbarHeight = action.payload
    },
    setIsInstalled(state, action) {
      state.isInstalled = action.payload
    },
  },
})

// exports
export const {
  setDeviceType,
  setIsMobile,
  setMobileNavbarHeight,
  setIsInstalled,
} = preferencesSlice.actions
export const preferencesReducer = preferencesSlice.reducer

// selectors
export const {
  selectAll: selectAllpreferences,
  selectById: selectPreferencesById,
  selectEntities: selectPreferencesEntities,
} = preferencesAdapter.getSelectors((state) => state.preferences)

export const selectDeviceType = (state) => state.preferences.deviceType
export const selectMobileNavbarHeight = (state) =>
  state.preferences.mobileNavbarHeight
export const selectIsMobile = (state) => state.preferences.isMobile
export const selectIsInstalled = (state) => state.preferences.isInstalled
