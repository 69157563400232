import React from "react"
import { classNames } from "../utils/utils"
import missingIcon from "../assets/missing-icon.png"
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component"

const S3_BUCKET = process.env.S3_URL

export const handleBrokenImage = (event, src) => {
  event.target.src = src
  event.target.srcset = ""
}

const resizeUrl =
  "https://static.store.app/cdn-cgi/image/width=%WIDTH%,quality=%QUALITY%,format=auto/%SRC%"

const imageResizerConfig = {
  resizeUrl: resizeUrl,
  cfSrcSetUrl: resizeUrl + " %WIDTH%w",
  sizesMap: {
    sm: 256,
    md: 512,
    lg: 768,
    xl: 1024,
  },
}

const CfImage = React.forwardRef(
  (
    {
      defaultWidth,
      maxWidth,
      src = "",
      className,
      alt,
      onClick = () => {},
      quality = 85,
      sizesMap = imageResizerConfig.sizesMap,
      scrollPosition,
      useIntersectionObserver,
      ...rest
    },
    ref,
  ) => {
    const attr = imgAttributes({
      src,
      alt,
      maxWidth,
      defaultWidth,
      quality,
      sizesMap,
    })

    return (
      <LazyLoadImage
        {...rest}
        ref={ref}
        scrollPosition={scrollPosition}
        useIntersectionObserver={useIntersectionObserver}
        alt={rest.alt}
        className={classNames(className)}
        {...attr}
        threshold={500}
        onClick={onClick}
        onError={(event) => handleBrokenImage(event, missingIcon)}
      />
    )
  },
)

CfImage.displayName = "CfImage"

// FIX BUG IN LIBRARY CODE
const withMappedRef = (Component) => {
  return ({ forwardRef, ...props }) => {
    return <Component {...props} ref={forwardRef} />
  }
}

export default trackWindowScroll(withMappedRef(CfImage))

const imgAttributes = ({
  src,
  alt,
  maxWidth,
  defaultWidth,
  quality,
  sizesMap,
}) => {
  const loading = !src
  const pixel =
    "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" // transparent pixel

  const defaults = {
    alt: "",
    src: pixel,
  }

  if (loading) return defaults

  // Don't send dev traffic through cloudflare
  if (process.env.NODE_ENV !== "production") {
    return {
      ...defaults,
      src,
    }
  }

  const wMax = maxWidth || Math.max(...Object.values(sizesMap).map(Number))
  const wDefault = defaultWidth ? Math.min(defaultWidth, wMax) : wMax

  return {
    alt,
    src: getResizeURL(imageResizerConfig.resizeUrl, wDefault, src, quality),
    srcSet: getSrcSet(sizesMap, src, quality, wMax),
    sizes: getSizes(sizesMap, wMax, wDefault),
  }
}

const getResizeURL = (url, width, src, quality) => {
  let srcRel = src.replace(S3_BUCKET, "")
  return url
    .replace(/%WIDTH%/g, width)
    .replace("%SRC%", srcRel)
    .replace("%QUALITY%", quality)
}

const getSrcSet = (sMap, src, quality, wMax) => {
  const srcSet = []
  Object.values(sMap)
    .sort((a, b) => a - b)
    .forEach((w) => {
      if (!wMax || w <= wMax) {
        srcSet.push(
          getResizeURL(imageResizerConfig.cfSrcSetUrl, w, src, quality),
        )
      }
    })
  return srcSet
}

const getSizes = (sMap, wMax, wDefault) =>
  Object.entries(sMap)
    .filter(([k, v]) => v <= wMax)
    .map(([k, v]) => {
      if (v === (wMax || wDefault)) return ` ${v}px`
      return `(max-width: ${sizeToPx(k)}px) ${v}px`
    })
    .join(",")

const sizeToPx = (size) => {
  return {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
  }[size]
}
