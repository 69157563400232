import * as React from "react"
import { cva } from "class-variance-authority"
import { cn } from "../../utils/utils"
import { Link } from "react-router-dom"
import { TrackOnClick } from "./tracker.hoc"
import { genWarningsFactory } from "./tracker.utils"
import { assign, isFunction, isString } from "lodash/fp"

/*
 *** Analytics
 */
const buttonActions = {
  CLICK: "click",
  OPEN: "open",
}

const buttonTrackers = {
  NONE: "none",

  // navigation
  BACK_ARROW: "back_arrow",

  // navbar
  SIGN_UP: "sign_up",
  LOGIN: "login",
  FORGOT_PASSWORD: "forgot_password",
  RESET_PASSWORD: "reset_password",
  RESEND_VERIFICATION_EMAIL: "resend_verification_email",
  MOBILE_SEARCH_ICON: "mobile_search_icon",

  // home
  GET_APP: "get_app",
  SHARE_VIEW: "share_view",
  SHARE_VIEW_COPY_LINK: "share_view_copy_link",
  SHARE_VIEW_TO_TWITTER: "share_view_to_twitter",
  ALL_FILTERS: "all_filters",

  // home + listing
  FAVORITE_APP: "favorite_app",

  // listing
  VIEW_LIGHTHOUSE_REPORT: "view_lighthouse_report",
  SHARE_APP: "share_app",
  VIEW_SOCIAL: "view_social", // twitter, website, etc
  WRITE_REVIEW: "write_review",
  UPDATE_REVIEW: "update_review",
  STAR_RATING: "star_rating",

  // settings
  PLAN_PAYMENT_SETTINGS: "plan_payment_settings",
  PLAN_CARD_ACTION_FREE: "free_plan_card_action",
  PLAN_CARD_ACTION_VERIFIED: "verified_plan_card_action",
  PLAN_CARD_ACTION_GROWTH: "growth_plan_card_action",
  PLAN_CARD_ACTION_FALLBACK: "plan_card_action",

  // sidebar cta
  DEVELOPER_PORTAL: "claim_app_dev_portal",
  CTA_BOOST_LISTING: "cta_boost_listing",
  CTA_COMPLETE_VERIFICATION: "cta_complete_verification",
  CTA_ENHANCE_LISTING: "cta_enhance_listing",
  CTA_GET_LISTED: "cta_get_listed",
  CTA_DEV_ACCESS: "cta_get_dev_access",

  // claim domain
  CLAIM_DOMAIN_SUBMIT_URL: "claim_domain_submit_url",
  CLAIM_DOMAIN_SUBMIT_APP: "claim_domain_submit_app",
  CLAIM_DOMAIN_CLOSE_MODAL: "claim_domain_close_modal",
  CLAIM_DOMAIN_READY_TO_VERIFY: "claim_domain_ready_to_verify",
  CLAIM_DOMAIN_EXIT_MODAL: "claim_domain_exit_modal",

  // developer
  REFETCH_MANIFEST: "refetch_manifest",
  VIEW_LISTING: "view_listing",
  INSTALL_MODAL_DEMO: "open_install_modal_demo",

  // mobile hamburger
  HAMBURGER_LOGOUT: "hamburger_logout",
}

/*
 *** Variants
 */
const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm shadow-sm transition-colors disabled:pointer-events-none disabled:opacity-50 without-ring",
  {
    variants: {
      variant: {
        default: "bg-blue-500 text-white hover:bg-blue-500/90",
        destructive: "", // TODO! - ADD ERROR BTN
        outline: "", // TODO! - ADD OUTLINE BTN
        ghost: "bg-gray-50 disabled:text-gray-500", // TODO!  - ADD GHOST BTN
        secondary: "bg-blue-50 text-blue-500 hover:bg-blue-100",
        link: "text-primary !shadow-none",
      },
      size: {
        default: "h-11 px-4 py-2",
        sm: "h-9 rounded-md px-4",
        lg: "h-12 rounded-md px-5 font-bold",
        icon: "h-10 w-10",
        flex: "flex items-center justify-center rounded-md font-medium",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
)

/*
 *** Component
 */
const genWarnings = genWarningsFactory("Button", buttonTrackers)
const getAction = (asExternal) =>
  asExternal ? buttonActions.OPEN : buttonActions.CLICK
const Button = React.forwardRef(
  (
    {
      asLink = false,
      asExternal = false,
      newTab = false,
      asWebflow = false,
      tracker,
      variant,
      size,
      className,
      onClick,
      ...props
    },
    ref,
  ) => {
    let Comp = asLink ? Link : "button"

    // external link or new tab
    if (asLink) {
      if (asExternal) {
        const { to, ...rest } = props
        Comp = "a"
        props = {
          ...rest,
          href: to,
          target: "_blank",
          rel: "noreferrer noopener",
        }
      } else if (asWebflow) {
        const { to, ...rest } = props
        Comp = "a"
        props = {
          ...rest,
          href: to,
        }
      } else if (newTab) {
        props.target = "_blank"
      }
    }

    // styling
    let c =
      variant === "naked"
        ? className
        : cn(buttonVariants({ variant, size, className }))

    // tracker or component id (for custom events)
    const object = asLink ? "link" : "button"
    const action = getAction(asExternal)
    const event = React.useMemo(() => {
      return isString(tracker)
        ? tracker
        : isFunction(tracker)
        ? tracker(action, object)
        : tracker
    }, [tracker, action, object])
    const id = event?.id || event

    // warnings
    if (process.env.NODE_ENV !== "production") {
      c = genWarnings(id, c)
    }

    // with analytics
    if (tracker && tracker !== buttonActions.NONE) {
      const base = {
        id,
        object,
        action: `${action} ${id}`,
      }

      const onClickEvent = isString(event) ? base : assign(base, event)
      return (
        <TrackOnClick onClick={onClick} onClickEvent={onClickEvent}>
          <Comp className={c} ref={ref} {...props} />
        </TrackOnClick>
      )
    }

    return <Comp className={c} ref={ref} {...props} />
  },
)

Button.displayName = "Button"

export { Button, buttonVariants, buttonActions, buttonTrackers }
