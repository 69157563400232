import { createContext, useEffect, useRef, useState } from "react"

export const ScrollContext = createContext({})

const ScrollContextProvider = ({ children }) => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const elementRef = useRef(null)

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(elementRef?.current?.scrollTop || window.scrollY)
    }

    const element = elementRef?.current || window

    element.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      element.removeEventListener("scroll", handleScroll)
    }
  }, [elementRef])

  return (
    <ScrollContext.Provider value={{ scrollPosition, elementRef }}>
      {children}
    </ScrollContext.Provider>
  )
}

export const withScrollPosition = (Component) => (props) => (
  <ScrollContext.Consumer>
    {({ scrollPosition }) => (
      <Component {...props} scrollPosition={scrollPosition} />
    )}
  </ScrollContext.Consumer>
)

export default ScrollContextProvider
