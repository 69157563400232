import { useEffect, useMemo, useState } from "react"
import { DataTable } from "../../../../shared/components/ui/Datatable"
import { columns } from "./Columns"
import { BASE_URL, useAuthApi } from "../../../../api/api"
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from "../../../../shared/assets/CustomIcons"
import { Button, buttonTrackers } from "../../../../shared/components/ui/Button"
import { useSearchParams } from "react-router-dom"
import { parseInt } from "lodash"
import Searchbox from "../../../../shared/components/inputs/Searchbox"
import { CrawlSubmissionForm } from "./CrawlSubmissionForm"

const ROW_LIMIT = 10

export const AppTable = () => {
  const [data, setData] = useState({
    listings: [],
    meta: { row_count: 0, row_limit: 0, row_offset: 0 },
  })
  const [isLoading, setIsLoading] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const [search, setSearch] = useState("")
  const page = useMemo(() => {
    return parseInt(searchParams.get("page")) || 0
  }, [searchParams])

  const listings = useMemo(() => {
    return data.data ?? []
  }, [data])

  const authApi = useAuthApi()

  useEffect(() => {
    const fetchData = async () => {
      let response
      try {
        const url = new URL(`${BASE_URL}/admin/v2/listings`)
        const searchParams = new URLSearchParams()
        searchParams.append("row_offset", page * ROW_LIMIT)
        searchParams.append("row_limit", ROW_LIMIT)
        searchParams.append("publish", true)
        if (search) {
          searchParams.append("search", search)
        }
        response = await authApi.get(
          `${url.toString()}?${searchParams.toString()}`,
        )
        setData(response)
      } catch (error) {
        console.log("error", error)
      }
      setIsLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search])

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <div className="p-6">
      <div className="mb-6">
        <CrawlSubmissionForm />
      </div>
      <div className="flex flex-row gap-x-2 mb-2">
        <Searchbox
          handleQueryChange={(event) => setSearch(event.target.value)}
          className="p-3 rounded-xl"
          placeholder="Search for an app"
        />
        <span className="flex flex-row items-center gap-x-2">
          <Button
            disabled={page === 0}
            onClick={() => setSearchParams({ page: page - 1 })}
            tracker={buttonTrackers.NONE}
          >
            <ArrowLeftIcon className="w-5 h-5" />
          </Button>
          <p className="whitespace-nowrap">Page {page}</p>
          <Button
            disabled={data.meta?.row_count < data.meta?.row_limit}
            onClick={() => setSearchParams({ page: page + 1 })}
            tracker={buttonTrackers.NONE}
          >
            <ArrowRightIcon className="w-5 h-5" />
          </Button>
        </span>
      </div>
      <DataTable columns={columns} data={listings} />
    </div>
  )
}
