import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  isPending,
  isFulfilled,
  isRejected,
} from "@reduxjs/toolkit"
import { flow } from "lodash/fp"
import { authApi, BASE_URL } from "../api"
import { createRequestStatusSelector } from "../util"
import { fetchAllContentV2, selectAllListings } from "../listings/listing.slice"

export const refetchManifest = createAsyncThunk(
  `apps/refetchManifest`,
  async (appId) => {
    return await authApi.post(`${BASE_URL}/apps/${appId}/manifests/refetch`)
  },
)

export const fetchManifest = createAsyncThunk(
  `manifests/`,
  async ({ appId }) => await authApi.get(`${BASE_URL}/apps/${appId}/manifests`),
)

export const fetchAppManifests = createAsyncThunk(
  `manifests/byApp`,
  async ({ appId }) => await authApi.get(`${BASE_URL}/apps/${appId}/manifests`),
)

const isAPendingAction = isPending(fetchManifest, fetchAppManifests)
const isAFufilledAction = isFulfilled(fetchManifest, fetchAppManifests)
const isARejectedAction = isRejected(fetchManifest, fetchAppManifests)

const manifestsAdapter = createEntityAdapter({
  // Using composite id ~> PRIMARY KEY ("id", "manifest_type") in PSQL
  selectId: (manifest) => `${manifest.id}-${manifest.manifest_type}`,
})
const initialState = manifestsAdapter.getInitialState({
  status: "idle",
  error: null,
})
export const manifestsSlice = createSlice({
  name: "manifests",
  initialState,
  reducers: {
    // omit existing reducers here
    [`setMany`]: (state, action) => {
      const manifests = action.payload
      manifestsAdapter.setMany(state, manifests)
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchManifest.fulfilled, (state, action) => {
        const manifest = action.payload
        manifestsAdapter.setOne(state, manifest)
      })
      .addMatcher(isFulfilled(fetchAllContentV2), (state, action) => {
        const { manifest } = action.payload
        if (manifest) {
          manifestsAdapter.setOne(state, manifest)
        }
      })
      /* Request status handlers */
      .addMatcher(isAPendingAction, (state, action) => {
        const status = { status: "pending", error: null }
        if (fetchAppManifests.pending.match(action)) {
          state.requests.all = status
        } else {
          const id = action.meta.arg
          state.requests[id] = status
        }
      })
      .addMatcher(isAFufilledAction, (state, action) => {
        const status = { status: "fufilled", error: null }
        if (fetchAppManifests.fulfilled.match(action)) {
          state.requests.all = status
        } else {
          const id = action.meta.arg
          state.requests[id] = status
        }
      })
      .addMatcher(isARejectedAction, (state, action) => {
        const status = { status: "rejected", error: action.error }
        if (fetchAppManifests.rejected.match(action)) {
          state.requests.all = status
        } else {
          const id = action.meta.arg
          state.requests[id] = status
        }
      })
  },
})

// exports
export const manifestsActions = {
  ...manifestsSlice.actions,
  fetchManifest,
}
export const manifestsReducer = manifestsSlice.reducer

// selectors
export const {
  selectAll: selectAllManifests,
  selectById: selectManifestById,
  selectEntities: selectManifestEntities,
} = manifestsAdapter.getSelectors((state) => state.manifests)

// selectors
export const {
  selectRequestStatus: selectAppsRequestStatus,
  zipWithRequestStatus,
} = createRequestStatusSelector(manifestsSlice.name)

export const selectManifestsStatus = (state) => state.manifests.status

export const selectCurrentManifest = (sitePath) => (state) => {
  const listing = selectAllListings(state).find((item) => item.id === sitePath)
  const filterByAppId = (list) => {
    const v = list.filter((m) => m.app_id === listing.app_id)
    return v
  }

  return flow(
    selectAllManifests,
    filterByAppId,
    zipWithRequestStatus(state),
  )(state)
}
