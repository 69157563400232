import React, { useCallback, useEffect, useRef, useState } from "react"
import { classNames } from "../../utils/utils"
import * as Yup from "yup"
import { Formik } from "formik"
import {
  filter,
  first,
  flatten,
  flow,
  intersection,
  isEmpty,
  isNull,
  isUndefined,
  nth,
  omitBy,
  pick,
} from "lodash/fp"
import { useDropzone } from "react-dropzone"
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  PhotoIcon,
  SignalIcon,
} from "@heroicons/react/24/outline"
import FormikAutoSave from "./FormikAutoSave"
import {
  createOverride,
  patchOverride,
} from "../../../api/overrides/overrides.slice"
import UploadComponent, { IncludeExistingCheckbox } from "./UploadComponent"
import {
  getDefaultIcon,
  getDefaultMaskableIcon,
  isValidAspectRatio,
  isValidIconSize,
} from "../../../api/util"
import { CategoryDropdownItems } from "../../../features/home/components/CategoriesBar.desktop"
import { Menu, Switch } from "@headlessui/react"
import {
  ChevronDownIcon,
  ChevronUpIcon,
  LockClosedIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid"
import { categories as categoriesConfig } from "../../../features/home/constants/categories"
import TooltipWrapper, {
  DesktopPreviewsTooltipContent,
  MobilePreviewsTooltipContent,
} from "../popover/Tooltip"
import { fetchAllContentV2 } from "../../../api/listings/listing.slice"
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import { canvasPreview, createImageFile } from "../image/canvasPreview"
import { DesktopModal } from "../modal/Modal"
import { CropIcon } from "../../assets/CustomIcons"

export const getClass = ({ isSubmitting, isError, isSuccess }) => {
  if (isError) return "bg-red-50"
  if (isSuccess) return ""
  if (isSubmitting) return "animate-pulse"
  return ""
}

export const TextArea = ({
  field,
  meta,
  name,
  type,
  label,
  placeholder,
  onPaste,
  isSubmitting,
  isSuccess,
  isError,
  maxLength,
}) => {
  const props = {
    name,
    type,
    label,
    placeholder,
    onPaste,
    maxLength,
  }
  return (
    <div className="relative">
      <div
        className={classNames(
          "rounded-md border-0 border-transparent bg-gray-50 px-3 py-2 shadow-sm focus:ring-0",
          getClass({ isSubmitting, isError, isSuccess }),
        )}
      >
        <div className="grid grid-flow-col justify-between mb-1">
          <label
            htmlFor="name"
            className="block text-xs font-medium text-gray-500"
          >
            {label}
          </label>
          {!!props.maxLength && (
            <span className="block text-xs font-medium text-gray-500">
              {field.value.length}/{props.maxLength}
            </span>
          )}
        </div>
        <textarea
          className="block w-full border-0 bg-transparent p-0 text-sm text-gray-900 placeholder-gray-500 focus:ring-0"
          {...field}
          {...props}
        />
      </div>
      {isError && (
        <div className="space-y-0 text-center text-sm text-red-500">
          {meta.error}
        </div>
      )}
      {isSubmitting && (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <SignalIcon
            className="h-4 w-4 animate-spin text-gray-300"
            aria-hidden="true"
          />
        </div>
      )}

      {isSuccess && (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <CheckCircleIcon
            className="h-6 w-6 text-green-500"
            aria-hidden="true"
          />
        </div>
      )}
    </div>
  )
}

export const TextField = ({
  field,
  meta,
  name,
  type,
  label,
  placeholder,
  onPaste,
  className,
  inputClassName,
  disabled,
  isSubmitting,
  isSuccess,
  isError,
  onChange,
}) => {
  const props = {
    name,
    type,
    label,
    placeholder,
    onPaste,
  }

  const handleChange = (e) => {
    field.onChange(e)

    if (onChange) {
      onChange(e.target.value)
    }
  }

  return (
    // TODO: add transition for error messages
    <div>
      <div
        className={classNames(
          "relative rounded-md border-0 border-transparent shadow-sm",
          className,
          getClass({ isSubmitting, isError, isSuccess }),
        )}
      >
        {label && (
          <label
            htmlFor={name}
            className="block text-xs font-medium text-gray-500"
          >
            {label}
          </label>
        )}
        <input
          type="text"
          className={classNames(
            "block w-full border-0 bg-transparent p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm",
            getClass({ isSubmitting, isError, isSuccess }),
            inputClassName,
          )}
          {...field}
          {...props}
          disabled={isSubmitting ? true : disabled}
          onChange={handleChange}
        />
        {isError && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationTriangleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
        {isSubmitting && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <SignalIcon
              className="h-4 w-4 animate-spin text-gray-300"
              aria-hidden="true"
            />
          </div>
        )}

        {isSuccess && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <CheckCircleIcon
              className="h-6 w-6 text-green-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {isError && (
        <div className="space-y-0 text-center text-sm text-red-500">
          {meta.error}
        </div>
      )}
    </div>
  )
}

export const ToggleField = ({
  name,
  label,
  initialValue,
  setFieldValue,
  values,
  propertyChangedText,
}) => {
  // Inverted b/c switch is "show audit" and input value is "hide_audit"
  const isChecked = !Boolean(values.hide_audit)
  return (
    <>
      <div className="inline-flex w-full items-center justify-between">
        <p className="text-sm text-gray-800">{label}</p>
        <Switch
          checked={isChecked}
          onChange={(checked) => {
            // Also need to invert setFieldValue, b/c formik uses `hide_audit`
            // eg: show_audit:true -> hide_audit:false
            const hideAudit = !checked
            setFieldValue(name, hideAudit)
          }}
          className={classNames(
            isChecked ? "bg-blue-500" : "bg-gray-200",
            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-0",
          )}
        >
          <span
            className={classNames(
              isChecked ? "translate-x-5" : "translate-x-0",
              "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
            )}
          >
            <span
              className={classNames(
                isChecked
                  ? "opacity-0 duration-100 ease-out"
                  : "opacity-100 duration-200 ease-in",
                "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity",
              )}
              aria-hidden="true"
            >
              <svg
                className="h-3 w-3 text-gray-400"
                fill="none"
                viewBox="0 0 12 12"
              >
                <path
                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span
              className={classNames(
                isChecked
                  ? "opacity-100 duration-200 ease-in"
                  : "opacity-0 duration-100 ease-out",
                "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity",
              )}
              aria-hidden="true"
            >
              <svg
                className="h-3 w-3 text-blue-500"
                fill="currentColor"
                viewBox="0 0 12 12"
              >
                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
              </svg>
            </span>
          </span>
        </Switch>
      </div>
      <p className="text-xs text-gray-500">{propertyChangedText}</p>
    </>
  )
}

const getCategories = (name, primary, c) =>
  ({
    primary_category: c?.slice(2),
    sub_category: c.find((cat) => cat.id === primary)?.subCategories?.slice(1),
  })[name]

export const CategoryDropdown = ({
  name,
  label,
  placeholder,
  isSubmitting,
  isSuccess,
  isError,
  setFieldValue,
  values,
  hasGlobalOverrideOnResource,
}) => {
  const [categories, setCategories] = useState([])
  const primary = values.primary_category

  useEffect(() => {
    const c = getCategories(name, primary, categoriesConfig)
    setCategories(c)
  }, [name, primary])

  const hasZeroSubs =
    name === "primary_category" &&
    !getCategories("sub_category", primary, categoriesConfig)

  useEffect(() => {
    if (isSuccess && hasZeroSubs) {
      setFieldValue("sub_category", "")
    }
  }, [isSuccess, hasZeroSubs, setFieldValue])

  return (
    <>
      <Menu
        as="div"
        className={classNames(
          "relative rounded-md border-0 border-transparent bg-gray-50 px-3 py-2 shadow-sm",
          name === "sub_category" &&
            (categories?.length
              ? "!mt-4 max-h-20"
              : "duration-600 !mt-0 max-h-0 overflow-hidden !py-0 transition-all ease-in-out"),
          getClass({ isSubmitting, isError, isSuccess }),
        )}
      >
        {({ open }) => (
          <>
            <Menu.Button
              as="div"
              className={classNames(
                "block w-full border-0 bg-transparent p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm",
                hasGlobalOverrideOnResource || !categories
                  ? "cursor-not-allowed"
                  : "cursor-pointer",
              )}
              disabled={hasGlobalOverrideOnResource || !categories}
            >
              <label
                htmlFor="name"
                className="block text-xs font-medium text-gray-900"
              >
                {label}
              </label>
              <div
                className={classNames(
                  "flex flex-row items-center justify-between",
                  !!values[name] ? "text-gray-900" : "text-gray-500",
                )}
              >
                {categories?.find((cat) => cat.id === values[name])?.name ||
                  placeholder}
                {hasGlobalOverrideOnResource ? (
                  <LockClosedIcon className="-mt-5 h-5 w-5 text-gray-500" />
                ) : open ? (
                  <ChevronUpIcon
                    className={classNames(
                      (isSuccess || isError) && "hidden",
                      "-mt-3 h-5 w-5",
                    )}
                  />
                ) : (
                  <ChevronDownIcon
                    className={classNames(
                      (isSuccess || isError) && "hidden",
                      "-mt-3 h-5 w-5",
                    )}
                  />
                )}
              </div>
            </Menu.Button>
            <Menu.Items className="absolute bottom-[55px] left-0 z-30 mt-3 max-h-72 w-full overflow-y-scroll rounded-lg bg-white shadow-t-md scrollbar-hide">
              <CategoryDropdownItems
                categories={categories}
                handleClick={(category) => setFieldValue(name, category.id)}
              />
            </Menu.Items>
            {isError && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
            {isSubmitting && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <SignalIcon
                  className="h-4 w-4 animate-spin text-gray-300"
                  aria-hidden="true"
                />
              </div>
            )}

            {isSuccess && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <CheckCircleIcon
                  className="h-6 w-6 text-green-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </>
        )}
      </Menu>
    </>
  )
}

export const Dropdown = ({
  name,
  values,
  label,
  isSubmitting,
  isSuccess,
  isError,
  setFieldValue,
  options,
  className,
}) => {
  return (
    <div className={className ?? ""}>
      <Menu
        as="div"
        className={classNames(
          "relative rounded-md border-0 border-transparent bg-gray-50 px-3 py-2 shadow-sm",
          getClass({ isSubmitting, isError, isSuccess }),
        )}
      >
        {({ open }) => (
          <>
            <Menu.Button
              as="div"
              className="block w-full cursor-pointer border-0 bg-transparent p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
            >
              <label
                htmlFor="name"
                className="block text-xs font-medium text-gray-500"
              >
                {label}
              </label>
              <div
                className={classNames(
                  "flex flex-row items-center justify-between",
                  !!values[name] ? "text-gray-900" : "text-gray-500",
                )}
              >
                {options?.find((e) => e.id === values[name])?.name}
                {open ? (
                  <ChevronUpIcon
                    className={classNames(
                      (isSuccess || isError) && "hidden",
                      "-mt-3 h-5 w-5",
                    )}
                  />
                ) : (
                  <ChevronDownIcon
                    className={classNames(
                      (isSuccess || isError) && "hidden",
                      "-mt-3 h-5 w-5",
                    )}
                  />
                )}
              </div>
            </Menu.Button>
            <Menu.Items className="absolute left-0 top-[55px] z-30 mt-3 max-h-72 w-full overflow-y-scroll rounded-lg bg-white shadow-t-md scrollbar-hide">
              <div className="flex flex-col gap-y-1">
                {options.map((e) => (
                  <button
                    key={e.id}
                    className={classNames(
                      "rounded-md px-4 py-[10px] text-start hover:bg-gray-50",
                      values[name] === e.id
                        ? "!bg-blue-50 text-blue-500 hover:bg-blue-50"
                        : "text-gray-800",
                    )}
                    onClick={(event) => {
                      event.preventDefault()
                      setFieldValue(name, e.id)
                    }}
                  >
                    <div className="flex items-center gap-x-2">
                      <div className="flex flex-col">
                        <div className="text-sm font-normal">{e.name}</div>
                        <div className="text-sm font-light">
                          {e.description}
                        </div>
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            </Menu.Items>
            {isError && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
            {isSubmitting && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <SignalIcon
                  className="h-4 w-4 animate-spin text-gray-300"
                  aria-hidden="true"
                />
              </div>
            )}

            {isSuccess && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <CheckCircleIcon
                  className="h-6 w-6 text-green-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </>
        )}
      </Menu>
    </div>
  )
}

export const UploadIcon = ({
  name,
  values,
  setFieldValue,
  label,
  purpose,
  isSubmitting,
  isError,
  handleCropImage,
}) => {
  const [uploadError, setUploadError] = useState(null)
  const isEmpty = values[name].length === 0
  const defaultIcon = first(values[name])
  const { open, getInputProps } = useDropzone({
    accept: {
      "image/*": [".png", ".svg", ".jpeg", ".jpg"],
    },
    onDrop: useCallback(
      async (acceptedFiles) => {
        try {
          setUploadError(null)
          let file = acceptedFiles[0]
          let width, height
          await new Promise((resolve) => {
            let reader = new FileReader()
            reader.onload = function (file) {
              var image = new Image()
              image.src = file.target.result
              image.onload = function (file) {
                height = this.height
                width = this.width
                resolve(reader.result)
              }
            }
            reader.readAsDataURL(file)
          })

          // crop image before upload if aspect ratio is off
          if (handleCropImage && !isValidAspectRatio(width, height)) {
            file = await handleCropImage(file) // will enforce aspect ratio & min size
          } else {
            // run validation if crop not supported
            if (!isValidIconSize(width, height)) {
              setUploadError("Must be 250x250 or larger")
              return
            }

            if (!isValidAspectRatio(width, height)) {
              setUploadError("Aspect ratio must be 1:1")
              return
            }
          }

          file.purpose = purpose
          setFieldValue(name, [file])
        } catch {
          // ignore error on cancel image crop
        }
      },
      [name, purpose, setFieldValue, handleCropImage],
    ),
    multiple: false,
  })

  const Empty = () => (
    <div
      className={classNames(
        "flex h-16 w-16 items-center justify-center rounded-full border-2 border-dashed border-gray-300",
        purpose === "maskable" && "rounded-none",
      )}
    >
      <PhotoIcon className="h-8 w-8 text-gray-500" />
    </div>
  )

  return (
    <section>
      <div className={classNames("mt-4 flex")}>
        <div className="flex-shrink-0">
          {isEmpty ? (
            <Empty />
          ) : (
            <Thumb
              key={name}
              file={defaultIcon}
              imgClassName={classNames("h-[4rem] w-[4rem]")}
            />
          )}
        </div>
        <div className="ml-2 flex flex-col justify-between">
          <h3 className="text-xs font-medium text-gray-500">{label}</h3>
          <div className="flex gap-2">
            <button
              type="button"
              onClick={open}
              disabled={isSubmitting}
              className={classNames(
                "focus:ring-none inline-flex w-32 h-10 items-center justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-700 hover:bg-blue-200 focus:outline-none",
                getClass({ isSubmitting, isSuccess: false, isError }),
              )}
            >
              {isEmpty ? "Add Icon" : "Replace Icon"}
            </button>
            {!!handleCropImage && !isEmpty && (
              <button
                type="button"
                onClick={async () => {
                  let file = defaultIcon

                  const isFromAwsS3 = file?.src && file?.sizes && file?.type
                  if (isFromAwsS3) {
                    file = await createImageFile(file.src)
                  }

                  setFieldValue(name, [await handleCropImage(file)])
                }}
                className="focus:ring-none inline-flex w-14 h-10 items-center justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium hover:bg-gray-200 focus:outline-none"
              >
                <CropIcon className="text-gray-700" />
              </button>
            )}
          </div>

          <input {...getInputProps()} />
        </div>
      </div>
      <p className="pt-2">{uploadError}</p>
    </section>
  )
}

export const Thumb = ({ file, imgClassName = "h-[4rem] w-auto" }) => {
  const [loading, setLoading] = useState(false)
  const [thumb, setThumb] = useState(undefined)

  const isFromAwsS3 = file?.src && file?.sizes && file?.type
  useEffect(() => {
    if (!file) return
    if (isFromAwsS3) return

    setLoading(true)

    let reader = new FileReader()
    reader.onloadend = () => {
      setLoading(false)
      setThumb(reader.result)
    }

    reader.readAsDataURL(file)
  }, [file, isFromAwsS3])

  if (!file) {
    return null
  }

  if (loading) {
    return <p>loading...</p>
  }

  if (isFromAwsS3) {
    return (
      <img
        key={file.src}
        src={file.src}
        alt={file.sizes}
        className={imgClassName}
      />
    )
  }

  return (
    <img key={file.name} src={thumb} alt={file.name} className={imgClassName} />
  )
}

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

const withCropModalDefaultOpts = {
  aspect: [1, 1], // enforce aspect ratio (default = 1/1 or square)
  scale: 1, // scale the image (default = 1x)
  rotate: 0, // rotate around the origin (radians)
}

export const withCropModal =
  (Component, opts = {}) =>
  (props) => {
    const {
      aspect: _aspect,
      scale,
      rotate,
    } = Object.assign(withCropModalDefaultOpts, opts)

    // modal
    const [open, setOpen] = useState(false)

    // image crop
    const aspect = nth(0)(_aspect) / nth(1)(_aspect)

    const imgRef = useRef(null)
    const [imgSrc, setImgSrc] = useState("")
    const [crop, setCrop] = useState()
    const [completedCrop, setCompletedCrop] = useState()

    const [resolve, setResolve] = useState(() => {})
    const [reject, setReject] = useState(() => {})

    async function handleCrop(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          const imgSrc = reader.result?.toString() || ""
          setImgSrc(imgSrc)

          // to resolve with image
          // once crop is complete
          setResolve(() => resolve)
          setReject(() => reject)

          // open modal
          setOpen(true)
        }

        reader.readAsDataURL(file)
      })
    }

    function onImageLoad(e) {
      if (aspect) {
        const { width, height } = e.currentTarget
        setCrop(centerAspectCrop(width, height, aspect))
      }
    }

    async function onSave() {
      const image = imgRef.current

      const previewCanvas = document.createElement("canvas")
      canvasPreview(image, previewCanvas, completedCrop, scale, rotate)

      if (!image || !previewCanvas || !completedCrop) {
        throw new Error("Crop canvas does not exist")
      }

      // This will size relative to the uploaded image
      // size. If you want to size according to what they
      // are looking at on screen, remove scaleX + scaleY
      const scaleX = image.naturalWidth / image.width
      const scaleY = image.naturalHeight / image.height

      const offscreen = new OffscreenCanvas(
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
      )
      const ctx = offscreen.getContext("2d")
      if (!ctx) {
        throw new Error("No 2d context")
      }

      ctx.drawImage(
        previewCanvas,
        0,
        0,
        previewCanvas.width,
        previewCanvas.height,
        0,
        0,
        offscreen.width,
        offscreen.height,
      )

      const blob = await offscreen.convertToBlob({
        type: "image/png",
      })

      let file = new File([blob], "icon.png", { type: "image/png" })

      resolve(file)
    }

    function CropDimension() {
      // const ratio = _aspect.join(":")
      return (
        <div className="flex text-left text-gray-700 text-sm mr-4">
          {`Drag edges to crop image`}
        </div>
      )
    }

    function handleClose() {
      setOpen(false)
      reject()
    }

    return (
      <>
        <Component {...props} handleCropImage={handleCrop} />
        <DesktopModal
          key={`crop-modal-${props?.name}`}
          open={open}
          handleClose={handleClose}
          classes={{
            container: "bg-white p-6",
          }}
        >
          {!!imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => {
                if (percentCrop.width === 0 || percentCrop.height === 0) return
                setCrop(percentCrop)
              }}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              minHeight={250}
              minWidth={250}
              keepSelection={true}
              className="relative"
            >
              <img
                className="object-contain object-center h-[50vh] w-fit"
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
          <div className="mt-4 flex w-full flex-row justify-between items-start gap-2">
            <div>
              <CropDimension />
            </div>
            <div className="flex items-center gap-2">
              <button
                onClick={handleClose}
                className="rounded-lg bg-gray-50 px-4 py-[8px]"
              >
                Cancel
              </button>
              <button
                className={classNames(
                  "rounded-lg px-4 py-[8px] text-white bg-blue-500",
                )}
                onClick={onSave}
              >
                Save
              </button>
            </div>
          </div>
        </DesktopModal>
      </>
    )
  }

export const UploadMobilePreviews = ({
  name,
  values,
  setFieldValue,
  manifest,
  isSubmitting,
  isSuccess,
  isError,
  handleCropImage,
}) => {
  return (
    <>
      <section className="space-y-2">
        <div className="flex w-full flex-row justify-between">
          <h1 className="text-lg font-bold">Edit Mobile Previews</h1>
          <TooltipWrapper
            trigger={
              <QuestionMarkCircleIcon className="h-5 w-5 text-gray-500" />
            }
          >
            <MobilePreviewsTooltipContent />
          </TooltipWrapper>
        </div>

        <IncludeExistingCheckbox
          platform="narrow"
          name={name}
          values={values}
          setFieldValue={setFieldValue}
          manifest={manifest}
        />
      </section>
      <section>
        <UploadComponent
          name={name}
          values={values}
          setFieldValue={setFieldValue}
          isSubmitting={isSubmitting}
          isSuccess={isSuccess}
          isError={isError}
          handleCropImage={handleCropImage}
        />
      </section>
    </>
  )
}

export const UploadDesktopPreviews = ({
  name,
  values,
  setFieldValue,
  manifest,
  isSubmitting,
  isSuccess,
  isError,
  handleCropImage,
}) => {
  return (
    <>
      <section className="space-y-2">
        <div className="flex w-full flex-row justify-between">
          <h1 className="text-lg font-bold">Edit Desktop Previews</h1>
          <TooltipWrapper
            trigger={
              <QuestionMarkCircleIcon className="h-5 w-5 text-gray-500" />
            }
          >
            <DesktopPreviewsTooltipContent />
          </TooltipWrapper>
        </div>
        <IncludeExistingCheckbox
          platform="wide"
          name={name}
          values={values}
          setFieldValue={setFieldValue}
          manifest={manifest}
        />
      </section>
      <section>
        <UploadComponent
          name={name}
          values={values}
          setFieldValue={setFieldValue}
          isSubmitting={isSubmitting}
          isSuccess={isSuccess}
          isError={isError}
          handleCropImage={handleCropImage}
        />
      </section>
    </>
  )
}

export const UploadHeadsetPreviews = ({
  name,
  values,
  setFieldValue,
  isSubmitting,
  isSuccess,
  isError,
  handleCropImage,
}) => {
  return (
    <>
      <section className="space-y-2">
        <div className="flex w-full flex-row justify-between">
          <h1 className="text-lg font-bold">Edit Headset Previews</h1>
        </div>
      </section>
      <section>
        <UploadComponent
          name={name}
          values={values}
          setFieldValue={setFieldValue}
          isSubmitting={isSubmitting}
          isSuccess={isSuccess}
          isError={isError}
          handleCropImage={handleCropImage}
        />
      </section>
    </>
  )
}

const SingleFieldForm = ({
  formikkey,
  fields = [],
  onSubmit,
  formClassName,
  autosave = true,
  showSuccessIcon = true,
}) => {
  const diffRef = useRef([])

  if (!fields.length) return <div>Loading...</div>

  const validationSchema = Yup.object(
    fields.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.name]: curr.yup,
      }),
      {},
    ),
  )

  const initialStatus = {
    fields: [],
    success: false,
    msg: "",
  }

  const initialValues = Object.assign(
    fields.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.name]:
          curr.initialValue || (curr.type === "checkbox" ? false : ""),
      }),
      {},
    ),
    {},
  )

  return (
    <Formik
      key={formikkey}
      initialStatus={initialStatus}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, config) => onSubmit(values, config, diffRef.current)}
    >
      {(formik) => {
        const isSubmittingGlobal = formik.isSubmitting
        return (
          <div className={formClassName ?? "space-y-4"}>
            {fields.map(
              ({
                Component = TextField,
                name,
                type,
                label,
                placeholder,
                ...rest
              }) => (
                <Component
                  {...rest}
                  key={name}
                  name={name}
                  type={type}
                  label={label}
                  placeholder={placeholder}
                  status={formik.status}
                  field={formik.getFieldProps(name)}
                  meta={formik.getFieldMeta(name)}
                  values={formik.values}
                  setFieldValue={formik.setFieldValue}
                  isSubmitting={
                    formik.status?.fields?.includes(name) && isSubmittingGlobal
                  }
                  isSuccess={
                    showSuccessIcon &&
                    formik.status?.fields?.includes(name) &&
                    !isSubmittingGlobal &&
                    !formik.status?.errors
                  }
                  isError={Boolean(
                    (formik.getFieldMeta(name).touched &&
                      formik.getFieldMeta(name).error) ||
                      (!!formik.status?.errors &&
                        formik.status?.fields?.includes(name)),
                  )}
                />
              ),
            )}

            {autosave && (
              <FormikAutoSave
                formik={formik}
                initialValues={initialValues}
                diffRef={diffRef}
              />
            )}
          </div>
        )
      }}
    </Formik>
  )
}

export default SingleFieldForm

export const formSubmitHandler =
  ({ dispatch, sitePath, appId, overrideId, allContent }) =>
  async (rawValues, { setSubmitting, setStatus, resetForm }, diff) => {
    try {
      // diff values to see what's changed
      // only pick what has changed
      const values = pick(diff)(rawValues)
      const isIconUpdate =
        diff.includes("icon") || diff.includes("maskableIcon")

      // keys to delete
      const deleteKeys = []
      let formData = new FormData()

      const isValidFilter = (value) =>
        !isUndefined(value) && !isNull(value) && !isEmpty(value)
      const isValidFileFilter = (value) => !isUndefined(value) && !isNull(value)

      // icons & screenshots
      const maybeIcons = isIconUpdate
        ? filter(isValidFilter)(
            flatten([rawValues.icon, rawValues.maskableIcon]),
          )
        : []

      const maybeMobileVerticalPreviews = filter(isValidFileFilter)(
        values.mobile_vertical_previews,
      )
      const maybeDesktopPreviews = filter(isValidFileFilter)(
        values.desktop_previews,
      )
      const maybeHeadsetPreviews = filter(isValidFileFilter)(
        values.headset_previews,
      )

      const appendFormData = (key, file) => {
        if (!file.src && !file.sizes && file.purpose === "maskable") {
          // backend will attach `purpose:maskable`
          // to any image w/ filename maskable
          formData.append(key, file, "maskable")
        } else if (!file.src && !file.sizes) {
          formData.append(key, file, "file")
        } else {
          // backend will save any existing files
          // if you append the img json
          // and the filename is "json"
          const blob = new Blob([JSON.stringify(file)], {
            type: "application/json",
          })
          const f = new File([blob], file.src)
          formData.append(key, f, "json")
        }
      }

      maybeIcons.forEach((file) => {
        appendFormData("icons", file)
      })

      maybeMobileVerticalPreviews.forEach((file) => {
        appendFormData("mobile_vertical_previews", file)
      })

      maybeDesktopPreviews.forEach((file) => {
        appendFormData("desktop_previews", file)
      })

      maybeHeadsetPreviews.forEach((file) => {
        appendFormData("headset_previews", file)
      })

      // provide zero values to force delete icons/ss
      if (maybeIcons.length === 0 && Object.keys(values).includes("icons")) {
        deleteKeys.push("icons")
      }

      if (
        maybeMobileVerticalPreviews.length === 0 &&
        Object.keys(values).includes("mobile_vertical_previews")
      ) {
        deleteKeys.push("mobile_vertical_previews")
      }

      if (
        maybeDesktopPreviews.length === 0 &&
        Object.keys(values).includes("desktop_previews")
      ) {
        deleteKeys.push("desktop_previews")
      }

      if (
        maybeHeadsetPreviews.length === 0 &&
        Object.keys(values).includes("headset_previews")
      ) {
        deleteKeys.push("headset_previews")
      }

      // links
      const allLinks = [
        "links:install:web_app",
        "links:install:ios",
        "links:install:android",
        "links:install:macos",
        "links:install:windows",
        "links:install:linux",
        "links:direct_install:ios",
        "links:direct_install:android",
        "links:direct_install:macos",
        "links:direct_install:windows",
        "links:direct_install:linux",
        "links:social:twitter",
        "links:social:website",
      ]
      const intersect = intersection(diff, allLinks)
      if (!!intersect.length) {
        const newLinks = {
          social: [],
          install: [],
          direct_install: [],
        }

        allLinks.forEach((link) => {
          const [, group, type] = link.split(":")
          if (diff.includes(link)) {
            if (values[link]) {
              newLinks[group].push({ url: values[link], type })
            }
          } else {
            const currLink = allContent.override?.links?.[group]?.find(
              (l) => l.type === type,
            )
            if (currLink) {
              newLinks[group].push(currLink)
            }
          }
        })

        const blob = new Blob([JSON.stringify(newLinks)], {
          type: "application/json",
        })
        const f = new File([blob], "file.json")
        formData.append("links", f, "json")
      }

      if (values.hide_audit !== undefined) {
        formData.append("hide_audit", values.hide_audit)
      }

      // other keys
      const validKeys = [
        "name",
        "short_name",
        "description",
        "short_description",
        "primary_category",
        "sub_category",
      ]
      const v = flow(omitBy(isUndefined), pick(validKeys))(values)

      for (let key in v) {
        if (isEmpty(v[key])) {
          deleteKeys.push(key)
        } else {
          formData.append(key, v[key])
        }
      }

      // append delete keys
      if (deleteKeys.length) {
        formData.append("delete_fields", deleteKeys.join(","))
      }

      let result
      if (overrideId) {
        result = await dispatch(
          patchOverride({
            formData,
            overrideId: overrideId,
          }),
        ).unwrap()
      } else {
        result = await dispatch(
          createOverride({ formData, appId: appId }),
        ).unwrap()
      }
      setSubmitting(false)
      setTimeout(() => setStatus({ fields: [] }), 2500)

      dispatch(fetchAllContentV2({ sitePath })).unwrap()

      const {
        patch,
        mobile_vertical_previews,
        desktop_previews,
        headset_previews,
        links,
      } = result
      const updates = diff.reduce(
        (acc, curr) => ({
          ...acc,
          [curr]:
            {
              icon: [].concat(getDefaultIcon(patch?.icons)),
              maskableIcon: [].concat(getDefaultMaskableIcon(patch?.icons)),
              name: patch?.name,
              primary_category: patch?.primary_category,
              sub_category: patch?.sub_category,
              description: patch?.description,
              short_description: patch?.short_description,
              mobile_vertical_previews: mobile_vertical_previews || [],
              desktop_previews: desktop_previews || [],
              headset_previews: headset_previews || [],
              "links:social:twitter": links?.social?.find(
                (l) => l.type === "twitter",
              )?.url,
              "links:social:website": links?.social?.find(
                (l) => l.type === "website",
              )?.url,
              "links:install:web_app": links?.install?.find(
                (l) => l.type === "web_app",
              )?.url,
              "links:install:ios": (() => {
                if (links?.direct_install?.find((l) => l.type === "ios")) {
                  return ""
                }
                return links?.install?.find((l) => l.type === "ios")?.url
              })(),
              "links:install:android": (() => {
                if (links?.direct_install?.find((l) => l.type === "android")) {
                  return ""
                }
                return links?.install?.find((l) => l.type === "android")?.url
              })(),
              "links:install:macos": (() => {
                if (links?.direct_install?.find((l) => l.type === "macos")) {
                  return ""
                }
                return links?.install?.find((l) => l.type === "macos")?.url
              })(),
              "links:install:windows": (() => {
                if (links?.direct_install?.find((l) => l.type === "windows")) {
                  return ""
                }
                return links?.install?.find((l) => l.type === "windows")?.url
              })(),
              "links:install:linux": (() => {
                if (links?.direct_install?.find((l) => l.type === "linux")) {
                  return ""
                }
                return links?.install?.find((l) => l.type === "linux")?.url
              })(),
              "links:direct_install:ios": links?.direct_install?.find(
                (l) => l.type === "ios",
              )?.url,
              "links:direct_install:android": links?.direct_install?.find(
                (l) => l.type === "android",
              )?.url,
              "links:direct_install:macos": links?.direct_install?.find(
                (l) => l.type === "macos",
              )?.url,
              "links:direct_install:windows": links?.direct_install?.find(
                (l) => l.type === "windows",
              )?.url,
              "links:direct_install:linux": links?.direct_install?.find(
                (l) => l.type === "linux",
              )?.url,
              hide_audit: patch?.hide_audit,
            }[diff] || "",
        }),
        {},
      )

      resetForm({
        status: { fields: diff },
        values: Object.assign({}, rawValues, updates),
      })
    } catch (error) {
      resetForm({
        status: { fields: diff, error: "Server error" },
      })
      setTimeout(() => setStatus({ fields: [] }), 2500)
      setSubmitting(false)
    }
  }
