import {
  DesktopIcon,
  MobileIcon,
  VRIcon,
} from "../../../shared/assets/CustomIcons"

export const PLATFORM = {
  ID: "platform",
  MOBILE: "web_app_mobile",
  DESKTOP: "web_app_desktop",
  INSTALLABLE: "web_app_pwa",
  HEADSET: "web_app_headset",
}

export const platforms = [
  { id: PLATFORM.MOBILE, icon: MobileIcon },
  { id: PLATFORM.DESKTOP, icon: DesktopIcon },
  { id: PLATFORM.HEADSET, icon: VRIcon },
]
