const TO_RADIANS = Math.PI / 180

// Adapted from library example
// https://codesandbox.io/s/react-image-crop-demo-with-react-hooks-y831o?file=/src/canvasPreview.ts:0-1702
export async function canvasPreview(
  image,
  canvas,
  crop,
  scale = 1,
  rotate = 0,
) {
  const ctx = canvas.getContext("2d")

  if (!ctx) {
    throw new Error("No 2d context")
  }

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height

  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

  ctx.scale(pixelRatio, pixelRatio)
  ctx.imageSmoothingQuality = "high"

  const cropX = crop.x * scaleX
  const cropY = crop.y * scaleY

  const rotateRads = rotate * TO_RADIANS
  const centerX = image.naturalWidth / 2
  const centerY = image.naturalHeight / 2

  ctx.save()

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY)
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY)
  // 3) Rotate around the origin
  ctx.rotate(rotateRads)
  // 2) Scale the image
  ctx.scale(scale, scale)
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY)
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  )

  ctx.restore()
}

export async function createImageFile(src) {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.crossOrigin = "Anonymous" // Support cross origin
    image.onload = async () => {
      const offscreen = new OffscreenCanvas(
        image.naturalWidth,
        image.naturalHeight,
      )
      const ctx = offscreen.getContext("2d")

      if (!ctx) {
        reject(new Error("No 2D context available"))
        return
      }

      ctx.drawImage(image, 0, 0) // Draw the image onto the offscreen canvas

      try {
        const blob = await offscreen.convertToBlob({ type: "image/png" })
        const file = new File([blob], "icon.png", { type: "image/png" })
        resolve(file)
      } catch (error) {
        reject(error)
      }
    }

    image.onerror = (error) => {
      reject(new Error("Image loading error: " + error.message))
    }

    image.src = src
  })
}
