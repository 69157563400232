import * as React from "react"
import { cva } from "class-variance-authority"
import { cn } from "../../utils/utils"
import { Link as RouterLink } from "react-router-dom"
import { TrackOnClick } from "./tracker.hoc"
import { genWarningsFactory } from "./tracker.utils"
import { assign, isFunction, isString } from "lodash/fp"

/*
 *** Analytics
 */
const linkActions = {
  CLICK: "click",
  OPEN: "open",
}

const linkTrackers = {
  NONE: "none",
  // auth
  FORGOT_PASSWORD: "forgot_password",
  SIGN_UP: "sign_up",
  LOGIN: "login",
  // navigation
  RETURN_HOME: "return_home",
  NAVBAR_LIST_APP: "navbar_list_app",
  NAVBAR_ABOUT: "navbar_about",
  PROMO_XR: "sidebar_xr",

  // sidebar
  SIDEBAR_FOR_YOU: "sidebar_for_you",
  SIDEBAR_BROWSE: "sidebar_browse",
  SIDEBAR_PROFILE: "sidebar_profile",
  SIDEBAR_SETTINGS: "sidebar_settings",
  SIDEBAR_DEVELOPER: "sidebar_developer",
  SIDEBAR_INSTALL_APP: "sidebar_install_app",
  SIDEBAR_CLAIMED_APP: "sidebar_claimed_app",
  SIDEBAR_LIST_FAVORITES: "sidebar_list_favorites",
  SIDEBAR_LIST_CUSTOM: "sidebar_list_custom",
  // footer
  CONTACT_SUPPORT: "contact_support",
  DISCORD_PAGE: "discord_page",
  TWITTER_PROFILE: "twitter_profile",
  // auth & footer
  PRIVACY_POLICY: "privacy_policy",
  TERMS_OF_SERVICE: "terms_of_service",
  // listing
  SHOW_ALL_PREVIEWS: "show_all_previews",
  ABOUT_THE_DEVELOPER: "about_the_developer",
  SOCIAL_PLATFORM_TWITTER: "social_platform_twitter",
  SOCIAL_PLATFORM_WEBSITE: "social_platform_website",
  // profile
  SOCIAL_PROFILE_TWITTER: "social_profile_twitter",
  SOCIAL_PROFILE_LINKEDIN: "social_profile_linkedin",
  SOCIAL_PROFILE_PERSONAL_LINK: "social_profile_personal_link",
  // developer
  INSTALL_MODAL_NPM_DOC: "open_install_modal_npm_doc",
  // mobile hamburger
  HAMBURGER_LIST_APP: "hamburger_list_app",
  HAMBURGER_ABOUT: "hamburger_about",
  HAMBURGER_PROFILE: "hamburger_profile",
  HAMBURGER_SETTINGS: "hamburger_settings",
  HAMBURGER_LIST_FAVORITES: "hamburger_list_favorites",
  HAMBURGER_LIST_CUSTOM: "hamburger_list_custom",
}

/*
 *** Variants
 */
const linkVariants = cva(
  "text-sm font-normal disabled:pointer-events-none disabled:opacity-50 without-ring",
  {
    variants: {
      variant: {
        default: "text-gray-500",
        blue: "text-blue-500 hover:text-blue-700",
        flex: "bg-white flex items-center justify-center rounded-md font-medium shadow-xs",
      },
      size: {
        default: "text-sm",
        sm: "text-xs",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
)

/*
 *** Component
 */
const genWarnings = genWarningsFactory("Link", linkTrackers)
const getAction = (asExternal) =>
  asExternal ? linkActions.OPEN : linkActions.CLICK
const Link = React.forwardRef(
  (
    {
      asLink = false,
      asExternal = false,
      newTab = false,
      asWebflow = false,
      tracker,
      variant,
      size,
      className,
      onClick,
      ..._props
    },
    ref,
  ) => {
    let Comp = RouterLink
    let props = _props

    // external link or new tab
    if (asExternal) {
      const { to, ...rest } = props
      Comp = "a"
      props = {
        ...rest,
        href: to,
        target: "_blank",
        rel: "noreferrer noopener",
      }
    } else if (asWebflow) {
      const { to, ...rest } = props
      Comp = "a"
      props = {
        ...rest,
        href: to,
      }
    } else if (newTab) {
      props.target = "_blank"
    }

    // styling
    let c =
      variant === "naked"
        ? className
        : cn(linkVariants({ variant, size, className }))

    // tracker or component id (for custom events)
    const object = "link"
    const action = getAction(asExternal)
    const event = React.useMemo(() => {
      return isString(tracker)
        ? tracker
        : isFunction(tracker)
        ? tracker(action, object)
        : tracker
    }, [tracker, action, object])
    const id = event?.id || event

    // warnings
    if (process.env.NODE_ENV !== "production") {
      c = genWarnings(id, c)
    }

    // with analytics
    if (tracker && tracker !== linkActions.NONE) {
      const base = {
        id,
        object,
        action: `${action} ${id}`,
      }

      const onClickEvent = isString(event) ? base : assign(base, event)
      return (
        <TrackOnClick onClick={onClick} onClickEvent={onClickEvent}>
          <Comp className={c} ref={ref} {...props} />
        </TrackOnClick>
      )
    }

    return <Comp className={c} ref={ref} {...props} />
  },
)

Link.displayName = "Link"

export { Link, linkVariants, linkActions, linkTrackers }
