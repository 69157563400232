import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
} from "@reduxjs/toolkit"
import { BASE_URL } from "../api"
import { fetchAllContentV2 } from "../listings/listing.slice"

export const runAudit = createAsyncThunk(
  `audits/run`,
  async ({ api, appId }) => await api.post(`${BASE_URL}/apps/${appId}/audits`),
)

const auditsAdapter = createEntityAdapter()
const initialState = auditsAdapter.getInitialState({
  requests: {},
})
export const auditsSlice = createSlice({
  name: "audits",
  initialState,
  reducers: {
    // omit existing reducers here
  },
  extraReducers(builder) {
    builder.addCase(fetchAllContentV2.fulfilled, (state, action) => {
      const { audits } = action.payload
      if (audits && Object.values(audits).length) {
        auditsAdapter.setMany(state, audits)
      }
    })
  },
})

// exports
export const auditsActions = { ...auditsSlice.actions }
export const auditsReducer = auditsSlice.reducer

// selectors
export const {
  selectAll: selectAllAudits,
  selectById: selectAuditById,
  selectEntities: selectAuditEntities,
} = auditsAdapter.getSelectors((state) => state.audits)

export const selectRecentAuditByAppId =
  (appId, findBy = () => true) =>
  (state) => {
    const audits = selectAllAudits(state)
      .filter((a) => a.app_id === appId)
      .sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))
      .map(({ url, ...rest }) => ({ src: url, ...rest }))
      .find(findBy)

    return { data: audits }
  }
