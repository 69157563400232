import { uniq } from "lodash/fp"
import {
  AppsIcon,
  GameIcon,
  SocialIcon,
  ProductivityIcon,
  CryptoIcon,
  ShoppingIcon,
  EntertainmentIcon,
  BriefcaseIcon,
  HammerIcon,
  EducationIcon,
  FinanceIcon,
  FitnessIcon,
  FoodIcon,
  DesignIcon,
  KidsIcon,
  LifestyleIcon,
  MedicalIcon,
  MusicIcon,
  NavigationIcon,
  NewsIcon,
  MediaIcon,
  ReferenceIcon,
  SportsIcon,
  TravelIcon,
  UtilitiesIcon,
  WandIcon,
  WeatherIcon,
  FeaturedIcon,
  SparklesIcon,
} from "../../../shared/assets/CustomIcons"

// Preserve order of categories and categoryIcons lists
// If adding subcategories, add an "all" subcategory to the beginning of the list

const CATEGORIES = [
  { id: "featured", name: "Featured", color: "black", icon: FeaturedIcon },
  { id: "all", name: "All Apps", color: "black", icon: AppsIcon },
  { id: "new", name: "New", color: "black", icon: SparklesIcon },
  { id: "ai", name: "AI", color: "pink", icon: WandIcon },
  { id: "business", name: "Business", icon: BriefcaseIcon },
  {
    id: "crypto",
    name: "Crypto",
    icon: CryptoIcon,
    color: "yellow",
    subCategories: [
      { id: "all", name: "All Crypto" },
      { id: "analytics", name: "Analytics" },
      { id: "base", name: "Base" },
      { id: "exchanges", name: "Exchanges" },
      { id: "ethereum", name: "Ethereum" },
      { id: "layerzero", name: "LayerZero" },
      { id: "chia", name: "Chia" },
      { id: "btc", name: "Bitcoin" },
      { id: "solana", name: "Solana" },
      { id: "polygon", name: "Polygon" },
      { id: "bnb", name: "BNB" },
      { id: "avalanche", name: "Avalanche" },
      { id: "arbitrum", name: "Arbitrum" },
      { id: "flow", name: "Flow" },
      { id: "aptos", name: "Aptos" },
      { id: "xrp", name: "XRP" },
      { id: "optimism", name: "Optimism" },
      { id: "cosmos", name: "Cosmos" },
      { id: "polkadot", name: "Polkadot" },
      { id: "base", name: "Base" },
      { id: "other", name: "Other" },
    ],
  },
  {
    id: "developer_tools",
    color: "red",
    name: "Dev Tools",
    icon: HammerIcon,
  },
  { id: "education", name: "Education", icon: EducationIcon },
  { id: "entertainment", name: "Entertainment", icon: EntertainmentIcon },
  { id: "finance", name: "Finance", icon: FinanceIcon },
  { id: "food_drink", name: "Food & Drink", icon: FoodIcon },
  {
    id: "games",
    name: "Games",
    icon: GameIcon,
    color: "blue",
    subCategories: [
      { id: "all", name: "All Games" },
      { id: "multiplayer", name: "Multiplayer" },
      { id: "singleplayer", name: "Singleplayer" },
      { id: "boardgame", name: "Boardgame" },
      { id: "strategy", name: "Strategy" },
    ],
  },
  { id: "graphics_design", name: "Graphics & Design", icon: DesignIcon },
  { id: "health_fitness", name: "Health & Fitness", icon: FitnessIcon },
  { id: "kids", name: "Kids", icon: KidsIcon },
  { id: "lifestyle", name: "Lifestyle", icon: LifestyleIcon },
  { id: "medical", name: "Medical", icon: MedicalIcon },
  { id: "music", name: "Music", icon: MusicIcon },
  { id: "navigation", name: "Navigation", icon: NavigationIcon },
  { id: "news", name: "News", icon: NewsIcon },
  { id: "photo_video", name: "Photo & Video", icon: MediaIcon },
  {
    id: "productivity",
    name: "Productivity",
    icon: ProductivityIcon,
    color: "purple",
  },
  { id: "reference", name: "Reference", icon: ReferenceIcon },
  { id: "shopping", name: "Shopping", icon: ShoppingIcon },
  { id: "social", color: "teal", name: "Social", icon: SocialIcon },
  { id: "sports", name: "Sports", icon: SportsIcon },
  { id: "travel", name: "Travel", icon: TravelIcon },
  { id: "utilities", name: "Utilities", icon: UtilitiesIcon },
  { id: "weather", name: "Weather", icon: WeatherIcon },
]

export { CATEGORIES as categories }

export const highlightedCategories = [
  "ai",
  "crypto",
  "games",
  "productivity",
  "developer_tools",
  "social",
]

export function findCategoryById(id, categories = CATEGORIES) {
  for (const category of categories) {
    if (category.id === id) {
      return {
        id: category.id,
        name: category.name,
        icon: category.icon,
        isPrimary: true,
        isCurrent: window.location.pathname?.split("/").includes(category.id),
        color: category?.color,
      }
    }

    if (category.subCategories) {
      for (const sub of category.subCategories) {
        if (sub.id === id) {
          return {
            id: sub.id,
            name: sub.name,
            icon: sub.icon,
            isPrimary: false,
          }
        }
      }
    }
  }

  if (process.env.NODE_ENV !== "production") {
    console.error(`Invalid category ID: ${id}`)
  }

  return null
}

export const flattenedCategoryIds = uniq(
  CATEGORIES.reduce((acc, category) => {
    if (
      category.id === "all" ||
      category.id === "featured" ||
      category.id === "new"
    )
      return acc

    acc.push(`category.${category.id}`)

    if (category.subCategories) {
      category.subCategories.forEach((subCategory) => {
        if (subCategory.id === "all" || subCategory.id === "featured")
          return acc
        acc.push(`${category.id}.${subCategory.id}`)
      })
    }

    return acc
  }, []),
)
