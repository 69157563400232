import { useDispatch, useSelector } from "react-redux"
import { Outlet, ScrollRestoration } from "react-router-dom"
import NotificationsSystem, {
  dismissNotification,
  setUpNotifications,
  wyboTheme,
} from "reapop"
import { usePageAnalytics } from "./api/analytics"
import { useShareLink } from "./api/share_links"
import { useIsMobile } from "./shared/hooks/useIsMobile"
import Layout from "./shared/components/layout/Layout"
import ScrollContextProvider from "./shared/scrollContext"
import "@storedotapp/pwa-install-dialog"
import { useIsInstalled } from "./features/home/hooks/useIsInstalled"

// run this function when your application starts before creating any notifications
setUpNotifications({
  defaultProps: {
    position: "bottom-right",
    dismissible: true,
  },
})

const App = () => {
  const dispatch = useDispatch()
  const notifications = useSelector((state) => state.notifications)

  useIsMobile()
  useIsInstalled()
  usePageAnalytics()
  useShareLink()

  return (
    <ScrollContextProvider>
      <ScrollRestoration />
      <Layout>
        <Outlet />
      </Layout>

      <NotificationsSystem
        notifications={notifications}
        // 3. Pass the function used to dismiss a notification.
        dismissNotification={(id) => dispatch(dismissNotification(id))}
        // 4. Pass a builtIn theme or a custom theme.
        theme={wyboTheme}
      />
      <pwa-install-dialog
        id="install-dialog"
        app="store-app"
        theme="light"
      ></pwa-install-dialog>
    </ScrollContextProvider>
  )
}

export default App
