import { useAuth } from "@clerk/clerk-react"
import { store } from "../shared/redux/store"
import { authActions } from "./auth/auth.slice"

export const BASE_URL = process.env.REACT_APP_API_URL

export const JwtBackendTemplate = "backend-api"

export const createApi = ({ authenticated = false } = {}) => {
  const methods = ["GET", "POST", "PUT", "DELETE", "PATCH", "HEAD"]

  return methods.reduce((acc, method) => {
    acc[method.toLowerCase()] = request(method, authenticated)
    return acc
  }, {})
}

const createApiV2 = ({ getToken }) => {
  const methods = ["GET", "POST", "PUT", "DELETE", "PATCH", "HEAD"]

  return methods.reduce((acc, method) => {
    acc[method.toLowerCase()] = requestV2(method, getToken)
    return acc
  }, {})
}

export const api = createApi()
export const authApi = createApi({ authenticated: true })

export const useAuthApi = () => {
  const { getToken } = useAuth()
  return createApiV2({ getToken })
}

function requestV2(method, getToken) {
  return (
    url,
    body,
    requestOptions = {
      method,
      headers: new Headers(),
      form: false,
    },
  ) => {
    // add mathod & default headers object
    requestOptions.method = method
    requestOptions.headers = requestOptions.headers || new Headers()

    // attach body if provided
    if (body) {
      if (requestOptions.form) {
        requestOptions.body = body
      } else {
        requestOptions.headers.append("Content-Type", "application/json")
        requestOptions.body = JSON.stringify(body)
      }
    }

    return handleFetchV2(url, requestOptions, getToken)
  }
}

export const expiresIn = (timestamp) =>
  (new Date(timestamp).valueOf() - new Date().valueOf()) / 1000

export const willExpireIn = (timestamp, seconds) =>
  expiresIn(timestamp) - seconds < 0

function request(method, addAuthHeaders = false) {
  return (
    url,
    body,
    requestOptions = {
      method,
      headers: new Headers(),
    },
  ) => {
    // add mathod & default headers object
    requestOptions.method = method
    requestOptions.headers = requestOptions.headers || new Headers()

    // attach body if provided
    if (body) {
      if (body instanceof FormData) {
        requestOptions.body = body
      } else {
        requestOptions.headers.append("Content-Type", "application/json")
        requestOptions.body = JSON.stringify(body)
      }
    }

    return handleFetch(url, requestOptions)
  }
}

async function handleFetchV2(url, opts, getToken) {
  const token = await getToken({ template: JwtBackendTemplate })
  opts.headers.append("Authorization", `Bearer ${token}`)
  const response = await fetch(url, opts)
  let text = await response.text()

  let res
  try {
    res = JSON.parse(text)
  } catch {
    res = text
  }

  if (!response.ok) {
    if (
      [401].includes(response.status) &&
      (!!opts.headers.get("Authorization") || url.includes("sessions/renew"))
    ) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      const logout = () => store.dispatch(authActions.logout())
      logout()
    }

    const message =
      res?.message || response.statusText || "Unknown server error"

    return Promise.reject({
      code: String(response.status),
      message,
    })
  }

  return res
}

async function handleFetch(url, opts) {
  const response = await fetch(url, opts)
  let text = await response.text()

  let res
  try {
    res = JSON.parse(text)
  } catch {
    res = text
  }

  if (!response.ok) {
    if (
      [401].includes(response.status) &&
      (!!opts.headers.get("Authorization") || url.includes("sessions/renew"))
    ) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      const logout = () => store.dispatch(authActions.logout())
      logout()
    }

    const message =
      res?.message || response.statusText || "Unknown server error"

    return Promise.reject({
      code: String(response.status),
      message,
    })
  }

  return res
}
