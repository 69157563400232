import { classNames } from "../../utils/utils"

const Searchbox = ({
  handleQueryChange,
  placeholder,
  className,
  name = "Searchbox",
}) => (
  <input
    name={name}
    className={classNames(
      "w-full rounded-md border-none bg-gray-100 text-sm font-light outline-blue-500 focus:outline focus:ring-0",
      className,
    )}
    placeholder={placeholder || "Search"}
    onChange={handleQueryChange}
  />
)
export default Searchbox
// ="w-full rounded-md border-none bg-gray-100 p-3 text-sm font-light focus:ring-0"
