import {
  IOSIcon,
  GlobeIcon,
  AndroidIcon,
  WindowsLogo,
  MacOSIcon,
  LinuxIcon,
} from "../../shared/assets/CustomIcons"

const deviceTypes = {
  web_app: {
    name: "Web App",
    icon: GlobeIcon,
  },
  ios: { name: "iOS", icon: IOSIcon },
  android: { name: "Android", icon: AndroidIcon },
  windows: { name: "Windows", icon: WindowsLogo },
  macos: { name: "MacOS", icon: MacOSIcon },
  linux: { name: "Linux", icon: LinuxIcon },
}

export default deviceTypes
