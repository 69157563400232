import { debounce } from "lodash"
import { isEqual } from "lodash/fp"
import { useCallback, useEffect } from "react"

const FormikAutoSave = ({ formik, debounceMs = 1000, diffRef }) => {
  // eslint-disable-next-line
  const debouncedSubmit = useCallback(
    debounce(() => {
      if (!formik.isValid || !formik.dirty) {
        return false
      }

      const p = formik.initialValues
      const c = formik.values
      const diff = Object.keys(p).filter((key) => !isEqual(p[key])(c[key]))
      formik.setStatus({ fields: diff })
      diffRef.current = diff

      return formik.submitForm()
    }, debounceMs),
    [
      formik.submitForm,
      formik.isValid,
      formik.initialValues,
      formik.values,
      formik.setStatus,
      formik.dirty,
      debounceMs,
    ],
  )

  useEffect(() => {
    debouncedSubmit()

    return debouncedSubmit.cancel
  }, [debouncedSubmit, formik.values, formik.dirty])

  return null
}

export default FormikAutoSave
