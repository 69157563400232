import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit"
import { flow, isEmpty, keyBy } from "lodash/fp"
import { api, authApi, BASE_URL } from "../api"
import { formatOneV2, selectAllListings } from "../listings/listing.slice"
import createCachedSelector from "re-reselect"

export const addFavorite = createAsyncThunk(
  `lists/favorites/post`,
  async ({ listId, appId }) =>
    await authApi.post(`${BASE_URL}/lists/favorites`, {
      app_id: appId,
      list_id: listId,
    }),
)

export const createList = createAsyncThunk(
  `lists/post`,
  async ({ name, listType }) =>
    await authApi.post(`${BASE_URL}/lists`, {
      name,
      list_type: listType,
    }),
)

export const deleteList = createAsyncThunk(`lists/delete`, async (listId) => {
  await authApi.delete(`${BASE_URL}/lists/${listId}`)
  return { listId }
})

export const addAppToList = createAsyncThunk(
  `lists/apps/post`,
  async ({ listId, appId }) =>
    await authApi.post(`${BASE_URL}/lists/${listId}/apps`, {
      app_id: appId,
    }),
)

export const removeAppFromList = createAsyncThunk(
  `lists/apps/delete`,
  async ({ listId, appId }) =>
    await authApi.delete(`${BASE_URL}/lists/${listId}/apps/${appId}`),
)

export const fetchList = createAsyncThunk(
  `lists/fetchList`,
  async (listId) => await api.get(`${BASE_URL}/lists/${listId}`),
)

export const fetchAllOwnedListsV2 = createAsyncThunk(
  `lists/fetchAllOwnedListsV2`,
  async () => await authApi.get(`${BASE_URL}/v2/lists`),
)

export const removeFavorite = createAsyncThunk(
  `lists/favorites/delete`,
  async ({ appId, listId }) => {
    await authApi.delete(`${BASE_URL}/lists/${listId}/apps/${appId}`)
  },
)

export const addFollower = createAsyncThunk(
  `lists/followers/post`,
  async ({ listId }, { getState }) => {
    await authApi.post(`${BASE_URL}/lists/${listId}/followers`)
    const userId = getState().auth.user?.id
    return { listId, userId }
  },
)

export const removeFollower = createAsyncThunk(
  `lists/followers/delete`,
  async ({ listId }, { getState }) => {
    await authApi.delete(`${BASE_URL}/lists/${listId}/followers`)
    const userId = getState().auth.user?.id
    return { listId, userId }
  },
)

export const fetchUserProfile = createAsyncThunk(
  `lists/userProfile/get`,
  async ({ username }, { getState }) =>
    await (getState().auth.user?.id ? authApi : api).get(
      `${BASE_URL}/profile/${username}`,
    ),
)

const isAPendingAction = isPending(fetchAllOwnedListsV2)
const isAFufilledAction = isFulfilled(fetchAllOwnedListsV2)
const isARejectedAction = isRejected(fetchAllOwnedListsV2)

const listsAdapter = createEntityAdapter()
const initialState = listsAdapter.getInitialState({
  requests: {},
})
export const listsSlice = createSlice({
  name: "lists",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllOwnedListsV2.fulfilled, (state, action) => {
        const lists = action.payload
        if (lists?.length) {
          const _lists = lists.map(({ listings, ...list }) => ({
            ...list,
            app_ids: listings.map((l) => l.app_id),
          }))
          listsAdapter.setAll(state, _lists)
        }
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        const list = action.payload
        if (!isEmpty(list)) {
          listsAdapter.setOne(state, list)
        }
      })
      .addCase(addFavorite.fulfilled, (state, action) => {
        const { listId, appId } = action?.meta?.arg
        if (!!listId) {
          if (state.entities[listId]) {
            const { app_ids = [] } = state.entities[listId]
            state.entities[listId].app_ids = [...app_ids, appId]
          } else {
            listsAdapter.setOne(state, {
              id: listId,
              name: "favorites",
              list_type: "favorites",
              app_ids: [appId],
              follower_ids: [],
            })
          }
        }
      })
      .addCase(createList.fulfilled, (state, action) => {
        const list = action.payload
        if (!isEmpty(list)) {
          listsAdapter.setOne(state, {
            ...list,
            app_ids: [],
            follower_ids: [],
          })
        }
      })
      .addCase(deleteList.fulfilled, (state, action) => {
        const { listId } = action.payload
        if (listId) {
          listsAdapter.removeOne(state, listId)
        }
      })
      .addCase(addAppToList.fulfilled, (state, action) => {
        const { listId, appId } = action?.meta?.arg

        if (listId && state.entities[listId]) {
          const { app_ids = [] } = state.entities[listId]
          state.entities[listId].app_ids = [...app_ids, appId]
        }
      })
      .addCase(removeAppFromList.fulfilled, (state, action) => {
        const { listId, appId } = action?.meta?.arg
        if (listId) {
          state.entities[listId].app_ids = state.entities[
            listId
          ]?.app_ids.filter((id) => id !== appId)
        }
      })
      .addCase(removeFavorite.fulfilled, (state, action) => {
        const { listId, appId } = action?.meta?.arg
        if (listId) {
          state.entities[listId].app_ids = state.entities[
            listId
          ]?.app_ids.filter((id) => id !== appId)
        }
      })
      .addCase(addFollower.fulfilled, (state, action) => {
        const { listId, userId } = action?.payload

        if (listId && state.entities[listId]) {
          const { follower_ids = [] } = state.entities[listId]
          state.entities[listId].follower_ids = [...follower_ids, userId]
        }
      })
      .addCase(removeFollower.fulfilled, (state, action) => {
        const { listId, userId } = action?.payload
        if (listId) {
          state.entities[listId].follower_ids = state.entities[
            listId
          ]?.follower_ids.filter((f) => f !== userId)
        }
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        const { lists } = action.payload
        if (lists?.length) {
          listsAdapter.upsertMany(state, lists)
        }
      })
      /* Request status handlers */
      .addMatcher(isAPendingAction, (state) => {
        const status = { status: "pending", error: null }
        state.requests.all = status
      })
      .addMatcher(isAFufilledAction, (state) => {
        const status = { status: "fufilled", error: null }
        state.requests.all = status
      })
      .addMatcher(isARejectedAction, (state, action) => {
        const status = { status: "rejected", error: action.error }
        state.requests.all = status
      })
  },
})

// exports
export const listsActions = { ...listsSlice.actions }
export const listsReducer = listsSlice.reducer

// selectors
export const {
  selectAll: selectAllLists,
  selectById: selectListById,
  selectEntities: selectListEntities,
} = listsAdapter.getSelectors((state) => state.lists)

export const selectIsFavorited = (appId) => (state) => {
  const favorites = getFavoritesList(state)
  if (favorites && favorites.app_ids) {
    return { value: favorites?.app_ids.includes(appId), id: favorites?.id }
  }

  return { value: false, id: favorites?.id }
}

export const selectIsAppInAnyList = (appId) => (state) => {
  const lists = selectAllLists(state)
  return lists?.some((l) => l.app_ids?.includes(appId))
}

export const selectIsUserFollowingList = (listId) => (state) => {
  const list = selectListById(state, listId)
  if (list) {
    const user = state.auth.user
    return list.follower_ids?.includes(user?.id) || list.user_id === user?.id
  }
  return false
}

export const selectAllOwnedLists = createCachedSelector(
  [
    (state) => state.lists?.entities,
    (state) => state.listings?.entities,
    (state) => state.auth?.user?.id,
  ],
  (listsEntities, listingsEntities, userId) => {
    const listings = keyBy("app_id")(Object.values(listingsEntities))
    const listIds = Object.keys(listsEntities)
    return flow(
      (ids) => ids?.filter((id) => listsEntities[id].user_id === userId),
      (ids) =>
        ids?.map((id) => {
          const list = listsEntities[id]
          return {
            ...list,
            listings: list?.app_ids?.map((id) => formatOneV2(listings[id])),
          }
        }),
    )(listIds)
  },
)((_) => "allOwnedLists")

export const selectAllOwnedListsByUserId = (userId) => (state) => {
  const listings = keyBy("app_id")(selectAllListings(state))
  return selectAllLists(state)
    .filter((l) => l.user_id === userId)
    .map((list) => ({
      ...list,
      listings: list?.app_ids?.map((id) => formatOneV2(listings[id])),
    }))
}

// helpers
const getFavoritesList = (state) => {
  const user = state.auth.user
  return selectAllLists(state).find(
    (l) => l.list_type === "favorites" && l.user_id === user?.id,
  )
}
