import {
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react"
import * as React from "react"
import { useSelector } from "react-redux"
import { selectIsMobile } from "../../../api/preferences/preferences.slice"
import { CheckCircleIcon } from "../../assets/CustomIcons"

export function useTooltip({
  initialOpen = false,
  placement = "top",
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen)

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: "start",
      }),
      shift({ padding: 5 }),
    ],
  })

  const context = data.context

  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null,
  })
  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: "tooltip" })

  const interactions = useInteractions([hover, focus, dismiss, role])

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data],
  )
}

const TooltipContext = React.createContext(null)

export const useTooltipContext = () => {
  const context = React.useContext(TooltipContext)

  if (context == null) {
    throw new Error("Tooltip components must be wrapped in <Tooltip />")
  }

  return context
}

function Tooltip({ children, ...options }) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options)
  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  )
}

export default function TooltipWrapper({ trigger, children, ...props }) {
  const isMobile = useSelector(selectIsMobile)
  if (isMobile) return trigger
  return (
    <Tooltip>
      <TooltipTrigger asChild {...props}>
        {trigger}
      </TooltipTrigger>
      <TooltipContent>{children}</TooltipContent>
    </Tooltip>
  )
}

export const TooltipTrigger = React.forwardRef(function TooltipTrigger(
  { children, asChild = false, ...props },
  propRef,
) {
  const context = useTooltipContext()
  const childrenRef = children.ref
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        "data-state": context.open ? "open" : "closed",
      }),
    )
  }

  return (
    <button
      ref={ref}
      // The user can style the trigger based on the state
      data-state={context.open ? "open" : "closed"}
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  )
})

export const TooltipContent = React.forwardRef(
  function TooltipContent(props, propRef) {
    const context = useTooltipContext()
    const ref = useMergeRefs([context.refs.setFloating, propRef])
    return (
      <FloatingPortal>
        {context.open && (
          <div
            ref={ref}
            style={{
              position: context.strategy,
              top: context.y ?? 0,
              left: context.x ?? 0,
              visibility: context.x == null ? "hidden" : "visible",
              ...props.style,
            }}
            {...context.getFloatingProps(props)}
          />
        )}
      </FloatingPortal>
    )
  },
)

export const PwaTooltipContent = () => (
  <div className="max-w-40 rounded-md bg-gray-800 p-1 text-white opacity-90">
    This is an installable web app
  </div>
)

export const ClaimedAppTooltipContent = () => (
  <div className="max-w-40 rounded-md bg-gray-800 p-1 text-white opacity-90">
    This app's listing has been claimed by the owner
  </div>
)

export const UnClaimedAppTooltipContent = () => (
  <div className="max-w-40 rounded-md bg-gray-800 p-1 text-white opacity-90">
    This app has not yet been claimed by the owner
  </div>
)

export const MobilePreviewsTooltipContent = () => (
  <div className="max-w-40 rounded-md bg-gray-800 p-1 text-white opacity-90">
    We recommend using a 19.5:9 aspect ratio for mobile previews
  </div>
)

export const DesktopPreviewsTooltipContent = () => (
  <div className="max-w-40 rounded-md bg-gray-800 p-1 text-white opacity-90">
    We recommend using a 4:3 aspect ratio for desktop previews
  </div>
)

export const LighthouseTooltipContent = () => (
  <div className="w-60 rounded-md bg-gray-800 p-1 text-white opacity-90">
    This score is based on a combination of this app's performance,
    accessibility, SEO, and best practices. Click "View Full Report" to learn
    more.
  </div>
)

export const BadLighthouseReportTooltipContent = () => (
  <div className="w-60 rounded-md bg-gray-800 p-1 text-white opacity-90">
    We experienced an issue running your lighthouse audit and have hidden this
    card from your listing temporarily. Please contact support if you continue
    to experience this issue
  </div>
)

export const ApprovedTooltipContent = () => (
  <div className="rounded-md bg-white px-3 py-2 drop-shadow-md">
    <h1 className="mb-1 text-base font-semibold text-blue-500">
      Only on Store.app
    </h1>
    <div className="flex flex-col space-y-1 text-sm font-light text-gray-700">
      <span className="inline-flex">
        <CheckCircleIcon className="mr-1 h-5 w-5 text-blue-500" />
        <p>Not listed in other app stores</p>
      </span>
      <span className="inline-flex">
        <CheckCircleIcon className="mr-1 h-5 w-5 text-blue-500" />
        <p>Optimized for mobile</p>
      </span>
      <span className="inline-flex">
        <CheckCircleIcon className="mr-1 h-5 w-5 text-blue-500" />
        <p>Showcases cutting edge web app features</p>
      </span>
    </div>
  </div>
)

export const VerifiedUserTooltipContent = () => (
  <div className="max-w-40 rounded-md bg-gray-800 p-1 text-white opacity-90">
    This developer has been verified by Store.app
  </div>
)

export const UnverifiedUserTooltipContent = () => (
  <div className="max-w-40 rounded-md bg-gray-800 p-1 text-white opacity-90">
    <p>This user has not yet been verified by Store.app</p>
  </div>
)

export const VerifiedOwnerTooltipContent = () => (
  <div className="max-w-40 rounded-md bg-gray-800 p-1 text-white opacity-90">
    The developer of this app has been verified by Store.app
  </div>
)
