import * as React from "react"
import { classNames } from "../../utils/utils"
import { ArrowDownIcon } from "../../assets/CustomIcons"

const Table = React.forwardRef(({ className, ...props }, ref) => (
  <div className="relative w-full overflow-auto rounded-xl border">
    <table
      ref={ref}
      className={classNames("w-full caption-bottom text-sm", className)}
      {...props}
    />
  </div>
))
Table.displayName = "Table"

const TableHeader = React.forwardRef(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={classNames("[&_tr]:border-b", className)}
    {...props}
  />
))
TableHeader.displayName = "TableHeader"

const TableBody = React.forwardRef(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={classNames("[&_tr:last-child]:border-0", className)}
    {...props}
  />
))
TableBody.displayName = "TableBody"

const TableFooter = React.forwardRef(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={classNames(
      "border-t bg-neutral-100/50 font-medium [&>tr]:last:border-b-0 dark:bg-neutral-800/50",
      className,
    )}
    {...props}
  />
))
TableFooter.displayName = "TableFooter"

const TableRow = React.forwardRef(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={classNames(
      "border-b transition-colors hover:bg-blue-50 data-[state=selected]:bg-bg-blue-200/30",
      className,
    )}
    {...props}
  />
))
TableRow.displayName = "TableRow"

const TableHead = React.forwardRef(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={classNames(
      "h-12 px-4 text-left align-middle font-medium text-neutral-500 [&:has([role=checkbox])]:pr-0 dark:text-neutral-400",
      className,
    )}
    {...props}
  />
))
TableHead.displayName = "TableHead"

const TableCell = React.forwardRef(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={classNames(
      "p-4 align-middle [&:has([role=checkbox])]:pr-0",
      className,
    )}
    {...props}
  />
))
TableCell.displayName = "TableCell"

const TableCaption = React.forwardRef(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={classNames(
      "mt-4 text-sm text-neutral-500 dark:text-neutral-400",
      className,
    )}
    {...props}
  />
))
TableCaption.displayName = "TableCaption"

const SortableHeader =
  (name) =>
  ({ column }) => {
    return (
      <button
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        className="inline-flex justify-center items-center gap-x-1"
      >
        {name}
        <ArrowDownIcon
          className={classNames(
            "h-4 w-4",
            column.getIsSorted === "asc" && "transform rotate-180",
            !column.getIsSorted && "hidden",
          )}
        />
      </button>
    )
  }

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
  SortableHeader,
}
