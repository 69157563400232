import { values } from "lodash/fp"
import { twMerge } from "tailwind-merge"

export const genWarningsFactory =
  (componentName, trackersObj) => (tracker, className) => {
    // just incase consumer forgets to wrap in process.env guard
    if (process.env.NODE_ENV === "production") return

    const missing = tracker === undefined
    if (missing) {
      console.warn(
        `Tracker is missing for "${componentName}" component. To intentionally omit, set tracker prop to "none"`,
      )
    }

    const unknown = !values(trackersObj).includes(tracker)
    if (unknown) {
      console.warn(
        `Uknown tracker "${tracker}" for "${componentName}" component. Add to tracker config at top of ${componentName}.js`,
      )
    }

    let modifier = ""
    if (missing) {
      modifier =
        "outline-dashed outline-2 outline-offset-2 outline-red-500 animate-bounce"
    } else if (unknown) {
      modifier =
        "outline-dashed outline-2 outline-offset-2 outline-yellow-500 animate-bounce"
    }

    // modi
    return twMerge(className, modifier)
  }
