import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { ArrowLeftIcon } from "../../assets/CustomIcons"
import {
  SignedIn,
  SignedOut,
  SignInButton,
  SignOutButton,
} from "@clerk/clerk-react"

const BackButton = ({ classes, to = -1 }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  if (searchParams.get("utm-medium") === "install-dialog") return null

  return (
    <button
      onClick={() =>
        navigate(location.key === "default" ? "/" : to, {
          preventScrollReset: true,
        })
      }
      className="-m-2 flex flex-row items-center justify-center rounded-full p-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 sm:rounded-lg sm:hover:bg-gray-50 sm:hover:text-gray-900 sm:px-3 sm:py-2"
    >
      <ArrowLeftIcon className={classes} aria-hidden="true" />
      <p className="hidden sm:flex">
        {to !== -1 ? "Back to all apps" : "Back"}
      </p>
    </button>
  )
}

const DesktopHomeNavbar = () => {
  return (
    <nav className="z-50 mx-auto">
      <div className="relative flex flex-col px-4 sm:flex-row lg:gap-8 lg:px-6 justify-between">
        <div className="hidden items-center justify-end space-x-2 sm:flex">
          <SignedOut>
            <SignInButton />
          </SignedOut>
          <SignedIn>
            <SignOutButton />
          </SignedIn>
        </div>
      </div>
    </nav>
  )
}

const Navbar = () => {
  return <DesktopHomeNavbar />
}

export { BackButton, Navbar }
