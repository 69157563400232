import * as React from "react"
import { useDispatch } from "react-redux"
import { analyticsTrack, pageDefaults } from "../../../api/analytics"
import {
  assign,
  forEach,
  isFunction,
  isString,
  isUndefined,
  omitBy,
} from "lodash/fp"
import { useLocation } from "react-router-dom"

const omitPredicate = (v) => isUndefined(v) || v === ""
// TODO! Rename to withTrackingOnClick
// withTrackingOpenClose etc...
export const withTracking =
  (BaseComponent, componentName, action, tracker) =>
  ({ onClick = () => {}, ...props }) => {
    const dispatch = useDispatch()
    const { path, title, search } = pageDefaults(useLocation())

    return (
      <BaseComponent
        {...props}
        onClick={(...args) => {
          const res = onClick(...args)
          dispatch(
            analyticsTrack({
              eventObject: componentName,
              eventAction: `${action} ${tracker}`,
              appId: props?.appId || props?.app_id,
              contextualProps: omitBy(omitPredicate)({
                action,
                target: componentName,
                target_id: tracker || props?.id,
                title: title,
                path: path,
                search_params: search,
              }),
            }),
          )
          return res
        }}
      />
    )
  }

export function TrackOnClick({
  children,
  onClick = () => {},
  onClickEvent,
  ...props
}) {
  const dispatch = useDispatch()
  const { path, title, search } = pageDefaults(useLocation())

  return React.cloneElement(children, {
    ...props,
    onClick: (...args) => {
      const res = onClick(...args)

      forEach(({ object, action, ...ctx }) => {
        dispatch(
          analyticsTrack({
            eventObject: object,
            eventAction: action,
            appId: ctx?.appId || ctx?.app_id || undefined,
            contextualProps: omitBy(omitPredicate)({
              action,
              target: object,
              target_id: ctx?.id,
              title: title,
              path: path,
              search_params: search,
              ...ctx,
            }),
          }),
        )
      })([].concat(onClickEvent))

      return res
    },
  })
}

// Analytics HOC
function withTrackMaker(eventKey) {
  return function (Component, object, actionFn = () => {}) {
    const WrappedComponent = (_props) => {
      const dispatch = useDispatch()
      const location = useLocation()

      let { tracker, ...props } = _props

      const getEvent = React.useCallback((tracker, action) => {
        const id = tracker?.id || tracker
        const base = {
          id: tracker,
          action: `${action} ${id}`,
          object,
        }

        if (isString(tracker)) {
          return base
        }

        return isFunction(tracker)
          ? assign(base, tracker(action, object))
          : assign(base, tracker)
      }, [])

      // Add analytics if tracker provided
      if (tracker) {
        // page info
        const { path, title, search } = pageDefaults(location)

        const { [eventKey]: onX, ...rest } = props
        props = {
          ...rest,
          [eventKey](value) {
            const res = onX(value)
            const { id, action, object, ...ctx } = getEvent(
              tracker,
              actionFn(value),
            )
            dispatch(
              analyticsTrack({
                eventObject: object,
                eventAction: action,
                appId: ctx?.appId || props?.appId || props?.app_id,
                contextualProps: omitBy(omitPredicate)({
                  action,
                  target: object,
                  target_id: id,
                  title: title,
                  path: path,
                  search_params: search,
                  ...ctx,
                }),
              }),
            )
            return res
          },
        }
      }

      return <Component {...props} />
    }

    // Copy all static properties
    Object.keys(Component).forEach((key) => {
      WrappedComponent[key] = Component[key]
    })

    return WrappedComponent
  }
}

export const withTrackOnChange = withTrackMaker("onChange")
export const withTrackOnPressedChange = withTrackMaker("onPressedChange")

