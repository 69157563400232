import { useDispatch } from "react-redux"
import { getMaskableFallbackIcon } from "../../../../api/util"
import CFImage from "../../../../shared/components/CFImage"
import { Button, buttonTrackers } from "../../../../shared/components/ui/Button"
import {
  deleteApp,
  updateListing,
} from "../../../../api/listings/listing.slice"
import { Modal } from "../../../../shared/components/modal/Modal"
import { useCallback, useState } from "react"
import { TrashIcon } from "@heroicons/react/24/outline"
import { Switch } from "@headlessui/react"
import SingleFieldForm from "../../../../shared/components/inputs/SingleFieldForm"
import * as Yup from "yup"
import { pick } from "lodash/fp"
import { useAuthApi } from "../../../../api/api"
import {
  AndroidIcon,
  IOSIcon,
  MacOSIcon,
  WebAppIcon,
} from "../../../../shared/assets/CustomIcons"

const DeleteCell = ({ row }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  return (
    <>
      <button onClick={() => setOpen(true)}>
        <TrashIcon className="w-6 h-6 text-red-500" />
      </button>
      <Modal open={open} handleClose={() => setOpen(false)}>
        <div className="flex flex-col gap-4 p-6">
          Are you sure?
          <Button
            tracker={buttonTrackers.NONE}
            className="bg-red-500 hover:bg-red-600"
            onClick={() => dispatch(deleteApp({ appId: row.id }))}
          >
            Delete
          </Button>
        </div>
      </Modal>
    </>
  )
}

const SwitchCell = ({ row, accessorKey }) => {
  const dispatch = useDispatch()
  const checked = row.original[accessorKey]
  const api = useAuthApi()
  return (
    <Switch
      className={`${
        checked ? "bg-blue-600" : "bg-gray-200"
      } relative inline-flex h-6 w-11 items-center rounded-full transition-colors`}
      checked={checked}
      onChange={() =>
        dispatch(
          updateListing({ api, id: row.original.id, [accessorKey]: !checked }),
        )
      }
    >
      <span
        className={`${
          checked ? "translate-x-6" : "translate-x-1"
        } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
      />
    </Switch>
  )
}

const InternalRankingCell = ({ row }) => {
  const listing = row.original
  const dispatch = useDispatch()
  const authApi = useAuthApi()
  const handleFormUpdate = useCallback(
    (id) =>
      async (rawValues, { setSubmitting, setStatus, resetForm }, diff) => {
        try {
          const { internal_ranking: ranking } = pick(diff)(rawValues)

          await dispatch(
            updateListing({
              api: authApi,
              id,
              internalRanking: Number(ranking),
            }),
          ).unwrap()

          setSubmitting(false)
          setTimeout(() => setStatus({ fields: [] }), 2500)

          // TODO! Patch in result
          const updates = {}

          resetForm({
            status: { fields: diff },
            values: Object.assign({}, rawValues, updates),
          })
        } catch (error) {
          resetForm({
            status: { fields: diff, error: "Server error" },
          })
          setTimeout(() => setStatus({ fields: [] }), 2500)
          setSubmitting(false)
        }
      },
    [dispatch, authApi],
  )

  return (
    <SingleFieldForm
      formikkey="internal_ranking"
      onSubmit={handleFormUpdate(listing.id)}
      fields={[
        {
          name: "internal_ranking",
          type: "text",
          yup: Yup.number().integer(),
          placeholder: "0-100",
          initialValue: String(listing.internal_ranking),
          className: "[&>*]:bg-white",
          inputClassName: "sm:!text-sm font-semibold !w-11",
        },
      ]}
    />
  )
}

const getAttributesByPrefixes = (attributes, prefix = []) =>
  attributes?.filter((attr) => prefix.some((p) => attr.startsWith(p)))

const platformToIcon = {
  "platform.android": <AndroidIcon />,
  "platform.ios": <IOSIcon />,
  "platform.macos": <MacOSIcon />,
  "platform.web": <WebAppIcon />,
}

export const columns = [
  {
    accessorKey: "publish",
    header: "Publish",
    cell: ({ row }) => <SwitchCell row={row} accessorKey="publish" />,
  },
  {
    accessorKey: "discoverable",
    header: "Discoverable",
    cell: ({ row }) => <SwitchCell row={row} accessorKey="discoverable" />,
  },
  {
    accessorKey: "icon",
    header: "Icon",
    cell: ({ row }) => (
      <CFImage
        className="w-12 aspect-square"
        src={getMaskableFallbackIcon(row.original.icons)?.src}
      />
    ),
  },
  { accessorKey: "name", header: "Name" },
  { accessorKey: "description", header: "Description" },
  {
    accessorKey: "internal_ranking",
    header: "Ranking",
    cell: InternalRankingCell,
  },
  {
    accessorKey: "platforms",
    header: "Platforms",
    cell: ({ row }) => (
      <p className="max-w-20">
        {getAttributesByPrefixes(row.original.attributes, ["platform"]).map(
          (plat) => platformToIcon[plat],
        )}
      </p>
    ),
  },
  {
    accessorKey: "attributes",
    header: "Attributes",
    cell: ({ row }) => (
      <p className="max-w-20">
        {getAttributesByPrefixes(row.original.attributes, [
          "category",
          "crypto",
          "games",
        ]).join(", ")}
      </p>
    ),
  },
  {
    accessorKey: "first_published_at",
    header: "Created at",
    cell: ({ row }) => {
      return new Date(row.original.first_published_at).toLocaleString()
    },
  },
  {
    accessorKey: "delete",
    header: "Delete",
    cell: DeleteCell,
  },
  {
    accessorKey: "edit",
    header: "Edit",
    cell: ({ row }) => (
      <a href={`/developer/admin/${row.original.id}`} className="text-blue-500">
        Edit
      </a>
    ),
  },
]
