import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit"
import { isEmpty } from "lodash/fp"
import { api, authApi, BASE_URL } from "../api"
import { buildUrl, createRequestStatusSelector } from "../util"
import { fetchUserProfile } from "../lists/lists.slice"

export const listReviewsByApp = createAsyncThunk(
  `reviews/getByApp`,
  async (appId) => await api.get(`${BASE_URL}/apps/${appId}/reviews`),
)

export const listReviewsByAppsOwner = createAsyncThunk(
  `reviews/getByAppsOwner`,
  async ({ username, ...args }) =>
    api.get(
      buildUrl(`${BASE_URL}/profile/${username}/reviews`, args, [
        "limit",
        "offset",
        "search",
      ]),
    ),
)

export const listReviewsByAuthorId = createAsyncThunk(
  `reviews/getByAuthorId`,
  async ({ userId, ...args }) =>
    api.get(
      buildUrl(`${BASE_URL}/users/${userId}/reviews`, args, [
        "limit",
        "offset",
      ]),
    ),
)

export const createReview = createAsyncThunk(
  `reviews/create`,
  async ({ rating, content = "", appId, reviewType }) =>
    await authApi.post(`${BASE_URL}/reviews`, {
      rating,
      content,
      app_id: appId,
      review_type: reviewType,
    }),
)

export const listTopReviews = createAsyncThunk(
  `reviews/getTop`,
  async (username) =>
    await api.get(`${BASE_URL}/profile/${username}/reviews/top`),
)

export const togglePinReview = createAsyncThunk(
  `reviews/togglePin`,
  async ({ direction, reviewId }) =>
    direction
      ? await authApi.post(`${BASE_URL}/profile/pinned-reviews/${reviewId}`)
      : await authApi.delete(`${BASE_URL}/profile/pinned-reviews/${reviewId}`),
)

export const updateReview = createAsyncThunk(
  `reviews/update`,
  async ({ rating, content = "", reviewType, reviewId }) =>
    await authApi.put(`${BASE_URL}/reviews/${reviewId}`, {
      rating,
      content,
      review_type: reviewType,
    }),
)

export const upsertVerifiedReview = createAsyncThunk(
  `reviews/upsertVerified`,
  async ({
    token,
    user: { firstName: first_name, lastName: last_name, email },
    review: { rating, content, reviewType: review_type, appId: app_id },
  }) => {
    return await api.put(`${BASE_URL}/reviews/verified`, {
      token,
      user: {
        first_name,
        last_name,
        email,
      },
      review: {
        rating,
        content,
        review_type,
        app_id,
      },
    })
  },
)

const isAPendingAction = isPending(listReviewsByApp)
const isAFulfilledAction = isFulfilled(listReviewsByApp)
const isARejectedAction = isRejected(listReviewsByApp)
const reviewsAdapter = createEntityAdapter()
const initialState = reviewsAdapter.getInitialState({
  requests: {},
})
export const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    // omit existing reducers here
  },
  extraReducers(builder) {
    builder
      .addCase(createReview.fulfilled, (state, action) => {
        const review = action.payload
        reviewsAdapter.setOne(state, review)
      })
      .addCase(upsertVerifiedReview.fulfilled, (state, action) => {
        const review = action.payload
        reviewsAdapter.upsertOne(state, review)
      })
      .addCase(updateReview.fulfilled, (state, action) => {
        const review = action.payload
        reviewsAdapter.upsertOne(state, review)
      })
      .addCase(listReviewsByApp.fulfilled, (state, action) => {
        const reviews = action.payload
        reviewsAdapter.setMany(state, reviews)
      })
      .addCase(listReviewsByAppsOwner.fulfilled, (state, action) => {
        const reviews = action.payload.data
        reviewsAdapter.setMany(state, reviews)
      })
      .addCase(listTopReviews.fulfilled, (state, action) => {
        const reviews = action.payload
        reviewsAdapter.upsertMany(state, reviews)
      })
      .addCase(listReviewsByAuthorId.fulfilled, (state, action) => {
        const reviews = action.payload.data
        reviewsAdapter.upsertMany(state, reviews)
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        const { reviews } = action.payload
        if (reviews?.length) {
          reviewsAdapter.upsertMany(state, reviews)
        }
      })
      .addCase(togglePinReview.fulfilled, (state, action) => {
        const review = action.payload
        reviewsAdapter.upsertOne(state, {
          id: review.id,
          pinned: review.pinned,
        })
      })
      /* Request status handlers */
      .addMatcher(isAPendingAction, (state, action) => {
        const status = { status: "pending", error: null }
        if (listReviewsByApp.pending.match(action)) {
          state.requests.all = status
        } else {
          const id = action.meta.arg
          state.requests[id] = status
        }
      })
      .addMatcher(isAFulfilledAction, (state, action) => {
        const status = { status: "fufilled", error: null }
        if (listReviewsByApp.fulfilled.match(action)) {
          state.requests.all = status
        } else {
          const id = action.meta.arg
          state.requests[id] = status
        }
      })
      .addMatcher(isARejectedAction, (state, action) => {
        const status = { status: "rejected", error: action.error }
        if (listReviewsByApp.rejected.match(action)) {
          state.requests.all = status
        } else {
          const id = action.meta.arg
          state.requests[id] = status
        }
      })
  },
})

// exports
export const reviewsActions = { ...reviewsSlice.actions }
export const reviewsReducer = reviewsSlice.reducer

// selectors
export const {
  selectRequestStatus: selectAppsRequestStatus,
  zipWithRequestStatus,
} = createRequestStatusSelector(reviewsSlice.name)

export const {
  selectAll: selectAllReviews,
  selectById: selectReviewById,
  selectEntities: selectReviewEntities,
} = reviewsAdapter.getSelectors((state) => state.reviews)

export const selectReviewsByApp =
  (appId, withStats = false) =>
  (state) => {
    const reviews = selectAllReviews(state).filter((a) => a.app_id === appId)
    const rInfo = state.reviews.requests.all
    const isLoading = rInfo?.status === "pending"
    const error = rInfo?.error
    const withContent = reviews.filter((r) => r.review_type === "review")
    const result = { data: withContent, isLoading, error }
    if (withStats) {
      return { ...result, stats: mapReviewsToStats(reviews) }
    }
    return result
  }

export const selectReviewsByApps = (appIds) => (state) =>
  selectAllReviews(state).filter((review) => appIds.includes(review.app_id))

export const selectPinnedReviews = (state) =>
  selectAllReviews(state).filter((review) => review.pinned === true)

export const selectExistingReview = (appId) => (state) => {
  const user = state.auth.user
  const reviews = selectAllReviews(state)
  const review = reviews.find(
    (r) => r.app_id === appId && r.user_id === user?.id,
  )
  const isReview = !isEmpty(review)
  return [review, isReview]
}

export const mapReviewsToStats = (reviews) => {
  if (reviews.length === 0) {
    return {
      average: 0,
      totalCount: 0,
      counts: [
        {
          rating: "1",
          count: 0,
        },
        {
          rating: "2",
          count: 0,
        },
        {
          rating: "3",
          count: 0,
        },
        {
          rating: "4",
          count: 0,
        },
        {
          rating: "5",
          count: 0,
        },
      ],
    }
  }

  const ratings = reviews.map((r) => r.rating)
  const average = ratings.reduce((p, c) => p + c, 0) / ratings.length
  const totalCount = ratings.length
  const rr = ratings.reduce(
    (acc, rating) => {
      acc[rating]++
      return acc
    },
    { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
  )

  const counts = Object.entries(rr).map(([rating, count]) => ({
    rating,
    count,
  }))

  return { average, totalCount, counts }
}
