import { createSlice, createEntityAdapter, isFulfilled } from "@reduxjs/toolkit"
import { flow, filter, find, keyBy, map } from "lodash/fp"
import { createRequestStatusSelector } from "../util"
import {
  authActions,
  isAFulfilledAuthAction,
  isAPendingAuthAction,
  isARejectedAuthAction,
  login,
  renewUserSession,
  signUp,
} from "../auth/auth.slice"
import { BASE_URL, authApi } from "../api"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const joinDevWaitlist = createAsyncThunk(
  `accounts/join-dev-waitlist`,
  async (values) => await authApi.post(`${BASE_URL}/waitlist/dev`, values),
)

// Admin Only
export const fetchAppOwnerByAppId = async (appId) =>
  await authApi.get(`${BASE_URL}/apps/${appId}/owner`)

const accounts = JSON.parse(localStorage.getItem("accounts")) || []
const accountsAdapter = createEntityAdapter()
const initialState = accountsAdapter.getInitialState({
  ids: map("id")(accounts),
  entities: keyBy("id")(accounts),
  requests: {},
})
export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    // omit existing reducers here
  },
  extraReducers(builder) {
    builder
      .addCase(authActions.logout.fulfilled, (state, action) => {
        // nuke accounts on logout
        localStorage.removeItem("accounts")
        accountsAdapter.removeAll(state)
      })
      .addMatcher(
        isFulfilled(login, signUp, renewUserSession),
        (state, action) => {
          const { accounts = [] } = action.payload

          // save accounts to local storage
          // to rehydrate on refresh
          localStorage.setItem("accounts", JSON.stringify(accounts))

          accountsAdapter.setMany(state, accounts)
        },
      )
      /* Request status handlers */
      .addMatcher(isAPendingAuthAction, (state, action) => {
        const status = { status: "pending", error: null }
        state.requests.all = status
      })
      .addMatcher(isAFulfilledAuthAction, (state, action) => {
        const status = { status: "fufilled", error: null }
        state.requests.all = status
      })
      .addMatcher(isARejectedAuthAction, (state, action) => {
        const status = { status: "rejected", error: action.error }
        state.requests.all = status
      })
  },
})

// exports
export const accountsActions = { ...accountsSlice.actions }
export const accountsReducer = accountsSlice.reducer

// selectors
const { zipWithRequestStatus } = createRequestStatusSelector(accountsSlice.name)
export const {
  selectAll: selectAllAccounts,
  selectById: selectAccountById,
  selectEntities: selectAccountEntities,
} = accountsAdapter.getSelectors((state) => state.accounts)

export const selectAccountByType =
  (accountType = "developer") =>
  (state) => {
    const userId = state.auth.user?.id
    const accounts = selectAllAccounts(state)

    return Object.assign(
      flow(
        filter("owner", userId),
        find(["account_type", accountType]),
        zipWithRequestStatus(state),
      )(accounts),
      { meta: { total: accounts.length } },
    )
  }

export const selectAccountRoles = (state) => {
  const userId = state.auth.user?.id

  const accountTypes = flow(
    selectAllAccounts,
    filter("owner", userId),
    map("account_type"),
  )(state)

  const isUser = accountTypes.includes("user")
  const isDeveloper = accountTypes.includes("developer")
  const isAdmin = accountTypes.includes("admin")

  return { isUser, isDeveloper, isAdmin }
}
