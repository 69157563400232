import {
  CheckCircleIcon,
  PlusCircleIcon,
  SignalIcon,
} from "@heroicons/react/24/outline"
import { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { TrashIcon } from "@heroicons/react/24/outline"
import { classNames } from "../../utils/utils"
import { trimCharsEnd, capitalize } from "lodash/fp"
import { getClass } from "./SingleFieldForm"
import { CropIcon } from "../../assets/CustomIcons"
import { createImageFile } from "../image/canvasPreview"
import { Button, buttonTrackers } from "../ui/Button"

const UploadComponent = ({
  name,
  values,
  setFieldValue,
  Component = Thumb,
  isSubmitting,
  isSuccess,
  isError,
  handleCropImage,
}) => {
  const isEmpty = values[name].length === 0
  const { getRootProps, getInputProps, isDragActive, isDragReject, open } =
    useDropzone({
      accept: {
        "image/*": [".png", ".svg", ".jpeg", ".jpg"],
      },
      onDrop: (acceptedFiles) => {
        // do nothing if no files
        if (acceptedFiles.length === 0) {
          return
        }

        // on drop we add to the existing files
        setFieldValue(name, values[name].concat(acceptedFiles))
      },
      noClick: !isEmpty,
    })

  const uploadLink = (
    <button
      type="button"
      onClick={open}
      disabled={isSubmitting}
      className="mx-auto flex items-center rounded-full border border-transparent p-2 text-blue-500 shadow-sm hover:bg-blue-50"
    >
      <PlusCircleIcon className="h-6 w-6" aria-hidden="true" />
      <p className="text-md pl-2">Upload Image</p>
    </button>
  )

  const content = () => {
    if (isDragActive) {
      return <p className="w-full text-center">Drop the files here ...</p>
    }

    if (isDragReject) {
      return <p className="w-full text-center">This file is not authorized</p>
    }

    if (isEmpty) {
      return (
        <div className="w-full text-center" onClick={open}>
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p>Click to upload or drag and drop</p>
          <p className="text-xs text-gray-500">PNG, JPG, SVG up to 10MB</p>
        </div>
      )
    }

    return values[name].map((file, i) => {
      const rmIndexThunk = (index) => () => {
        setFieldValue(
          name,
          values[name].filter((_, i) => i !== index),
        )
      }
      return (
        <Component
          handleDelete={rmIndexThunk(i)}
          key={i}
          index={i}
          file={file}
          open={open}
          name={name}
          isSubmitting={isSubmitting}
          isSuccess={isSuccess}
          isError={isError}
          handleCrop={async () => {
            try {
              let imageFile = file

              const isFromAwsS3 = file?.src && file?.sizes && file?.type
              if (isFromAwsS3) {
                imageFile = await createImageFile(file.src)
              }

              const croppedFile = await handleCropImage(imageFile)
              setFieldValue(
                name,
                values[name].map((el, idx) => (idx === i ? croppedFile : el)),
              )
            } catch {
              // ignore error on click cancel
            }
          }}
        />
      )
    })
  }

  return (
    <div {...getRootProps({ className: "dropzone" })}>
      <div
        className={classNames(
          (isEmpty || isDragActive || isDragReject) &&
            "flex h-36 max-w-lg items-center justify-start space-x-2 rounded-md border-2 border-dashed border-gray-300 p-2",
          !isEmpty && "flex flex-col space-y-2",
        )}
      >
        <input {...getInputProps()} />
        {content()}
        {!isEmpty && uploadLink}
      </div>
    </div>
  )
}

export const Thumb = ({
  file,
  handleDelete,
  index,
  name,
  isSubmitting,
  isSuccess,
  isError,
  handleCrop,
}) => {
  const [loading, setLoading] = useState(false)
  const [thumb, setThumb] = useState(undefined)

  const isFromAwsS3 = file.src && file.sizes && file.type
  useEffect(() => {
    if (!file) return
    if (isFromAwsS3) return

    setLoading(true)

    let reader = new FileReader()
    reader.onloadend = () => {
      setLoading(false)
      setThumb(reader.result)
    }

    reader.readAsDataURL(file)
  }, [file, isFromAwsS3])

  if (!file) {
    return null
  }

  if (loading) {
    return <p>loading...</p>
  }

  let icon = <TrashIcon className="h-6 w-6" aria-hidden="true" />
  if (isSubmitting) {
    icon = (
      <SignalIcon
        className="h-4 w-4 animate-spin text-gray-300"
        aria-hidden="true"
      />
    )
  } else if (isSuccess) {
    icon = (
      <CheckCircleIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
    )
  }

  return (
    <div
      className={classNames(
        "flex items-center justify-between overflow-hidden rounded-md bg-gray-50 px-3 py-2 shadow",
        getClass({ isSubmitting, isSuccess, isError }),
      )}
    >
      <div className="flex items-center">
        {isFromAwsS3 ? (
          <img src={file.src} alt={file.sizes} className="h-16 w-auto" />
        ) : (
          <img src={thumb} alt={file.name} className="h-16 w-auto" />
        )}
        <p className="pl-2 text-xs">{`${trimCharsEnd("s")(
          capitalize(name).replace("_preview", ""),
        )}-${index + 1}.${file.type?.replace("image/", "") || "png"}`}</p>
      </div>
      <div className="flex flex-center">
        {!!handleCrop && (
          <button
            type="button"
            onClick={handleCrop}
            className="-mr-1 inline-flex items-center rounded-full border border-transparent p-2 shadow-sm hover:bg-gray-200"
          >
            <CropIcon className="w-6 h-6 text-gray-700" />
          </button>
        )}
        <button
          type="button"
          onClick={handleDelete}
          disabled={isSubmitting}
          className="inline-flex items-center rounded-full border border-transparent p-2 shadow-sm hover:bg-gray-200"
        >
          {icon}
        </button>
      </div>
    </div>
  )
}

export const IncludeExistingCheckbox = ({
  name,
  values,
  setFieldValue,
  platform,
  manifest,
}) => {
  const relevantScreenshots = manifest?.screenshots?.filter(
    // if requested platform is narrow, include screenshots without platform
    (ss) => ss.platform === platform || (platform === "narrow" && !ss.platform),
  )
  if (!relevantScreenshots?.length) return null
  return (
    <Button
      tracker={buttonTrackers.NONE}
      variant="secondary"
      onClick={() => {
        setFieldValue(name, values[name].concat(relevantScreenshots))
      }}
    >
      Import {relevantScreenshots?.length} screenshots from current manifest
    </Button>
  )
}

export default UploadComponent
