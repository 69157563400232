import {
  createAsyncThunk,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit"
import { isEmpty, omitBy } from "lodash/fp"
import { api, authApi, BASE_URL, willExpireIn } from "../api"
import createCachedSelector from "re-reselect"
import { fetchUserProfile } from "../lists/lists.slice"

export const login = createAsyncThunk(
  `users/login`,
  async ({ email, password }) =>
    await api.post(`${BASE_URL}/users/login`, {
      email,
      password,
    }),
)

export const logout = createAsyncThunk(
  `users/logout`,
  async (_, { getState }) => {
    const { refreshToken, refreshTokenExpiresAt } = getState().auth
    const isRefreshExpired =
      !refreshToken || willExpireIn(refreshTokenExpiresAt, 0)
    if (isRefreshExpired) return Promise.resolve()

    return await api.post(`${BASE_URL}/users/logout`, {
      refresh_token: getState().auth?.refreshToken,
    })
  },
)

export const signUp = createAsyncThunk(
  `users/signup`,
  async (
    { email, password, first_name, last_name, username, verified_review_token },
    { getState },
  ) => {
    const { referralLink } = getState().auth
    return await api.post(
      `${BASE_URL}/users/signup`,
      omitBy(isEmpty)({
        email,
        password,
        first_name,
        last_name,
        username,
        verified_review_token,
        referral_link: referralLink,
      }),
    )
  },
)

export const renewUserSession = createAsyncThunk(
  `users/session/renew`,
  async (_, { getState }) =>
    await api.post(`${BASE_URL}/users/sessions/renew`, {
      refresh_token: getState().auth?.refreshToken,
    }),
)

export const renewUserSessionWithCondition = createAsyncThunk(
  `users/session/renew-with-condition`,
  async (predicate, { getState }) => {
    if (predicate(getState().auth?.user)) return

    var resp = await api.post(`${BASE_URL}/users/sessions/renew`, {
      refresh_token: getState().auth?.refreshToken,
    })

    for (let i = 0; i < 10 && !predicate(resp.user); ++i) {
      await new Promise((r) => setTimeout(r, 2500)) // sleep

      resp = await api.post(`${BASE_URL}/users/sessions/renew`, {
        refresh_token: getState().auth?.refreshToken,
      })
    }

    if (predicate(resp?.user)) {
      return resp
    }

    // if final check fails, manually reject
    // TODO! Improve messaging. We are waiting for a backend webhook from stripe
    // so maybe there's a way to pass up the failure state if something goes wrong
    // and the card request cannot be fulfilled
    return Promise.reject({
      code: "400",
      message: "Something went wrong. Please try again later",
    })
  },
)

export const updateUser = createAsyncThunk(
  `users/update`,
  async ({ userId, email, firstName, lastName, campaignBillingPreference }) =>
    await authApi.put(
      `${BASE_URL}/users/${userId}`,
      omitBy(isEmpty)({
        email,
        first_name: firstName,
        last_name: lastName,
        campaign_billing_preference: campaignBillingPreference,
      }),
    ),
)

export const resetPassword = createAsyncThunk(
  `users/password/reset`,
  async ({ userId, currentPassword, newPassword }) =>
    await authApi.put(
      `${BASE_URL}/users/${userId}/password`,
      omitBy(isEmpty)({
        current_password: currentPassword,
        new_password: newPassword,
      }),
    ),
)

export const checkUsername = createAsyncThunk(
  `users/check/username`,
  async (username) => await api.head(`${BASE_URL}/users/username/${username}`),
)

export const forgotPasswordLink = createAsyncThunk(
  `users/forgot-password`,
  async ({ email }) =>
    await api.post(`${BASE_URL}/users/forgot-password`, {
      email,
    }),
)

export const checkPasswordResetToken = createAsyncThunk(
  `users/reset-password/check-token`,
  async (token) =>
    await api.get(`${BASE_URL}/users/reset-password/verify?token=${token}`),
)

export const submitPasswordReset = createAsyncThunk(
  `users/reset-password`,
  async ({ token: reset_token, password: new_password }) =>
    await api.post(`${BASE_URL}/users/reset-password`, {
      new_password,
      reset_token,
    }),
)

export const verifyUser = createAsyncThunk(
  `users/verify`,
  async (token) =>
    await api.post(`${BASE_URL}/users/verify`, {
      token,
    }),
)

export const resendVerificationEmail = createAsyncThunk(
  `users/verify/resend`,
  async ({ email, token }) =>
    await api.post(
      `${BASE_URL}/users/verify/resend`,
      omitBy(isEmpty)({
        email,
        token,
      }),
    ),
)

const initialState = () => ({
  accessToken: JSON.parse(localStorage.getItem("accessToken")),
  expiresAt: JSON.parse(localStorage.getItem("expiresAt")),
  refreshToken: JSON.parse(localStorage.getItem("refreshToken")),
  refreshTokenExpiresAt: JSON.parse(
    localStorage.getItem("refreshTokenExpiresAt"),
  ),
  user: JSON.parse(localStorage.getItem("user")),
  referralLink: null,
  error: null,
  requests: {},
})

export const isAPendingAuthAction = isPending(
  login,
  signUp,
  updateUser,
  verifyUser,
  renewUserSession,
  renewUserSessionWithCondition,
)

export const isAFulfilledAuthAction = isFulfilled(
  login,
  signUp,
  updateUser,
  verifyUser,
  renewUserSession,
  renewUserSessionWithCondition,
)

export const isARejectedAuthAction = isRejected(
  login,
  signUp,
  updateUser,
  verifyUser,
  renewUserSession,
  renewUserSessionWithCondition,
)

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState(),
  reducers: {
    setReferralLink: (state, action) => {
      state.referralLink = action.payload
    },
  },
  extraReducers(builder) {
    builder
      /* Action handlers */
      .addCase(logout.fulfilled, (_) => {
        localStorage.removeItem("user")
        localStorage.removeItem("accessToken")
        localStorage.removeItem("expiresAt")
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("refreshTokenExpiresAt")
        localStorage.removeItem("profile")

        // return fresh initialState
        return initialState()
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        const { user_id, profile } = action.payload
        if (user_id === state.user?.id) {
          localStorage.setItem("profile", JSON.stringify(profile))
          state.profile = profile
        }
      })
      /* Request status handlers */
      .addMatcher(isAPendingAuthAction, (state, action) => {
        const status = { status: "pending", error: null }
        const id = action.type.replace("/pending", "")
        state.requests[id] = status
      })
      .addMatcher(isAFulfilledAuthAction, (state, action) => {
        const status = { status: "fulfilled", error: null }
        const id = action.type.replace("/fulfilled", "")
        state.requests[id] = status
        setUserSession(state, action.payload)
      })
      .addMatcher(isARejectedAuthAction, (state, action) => {
        const status = { status: "rejected", error: action.error }
        const id = action.type.replace("/rejected", "")
        state.requests[id] = status
      })
  },
})

// exports
export const authActions = {
  ...authSlice.actions,
  login,
  signUp,
  renewUserSession,
  logout,
  forgotPasswordLink,
}
export const authReducer = authSlice.reducer

// selectors
export const selectAccessToken = (state) => ({
  accessToken: state.auth.accessToken,
  expiresAt: state.auth.expiresAt,
  refreshToken: state.auth.refreshToken,
  refreshTokenExpiresAt: state.auth.refreshTokenExpiresAt,
})
export const selectAuthUser = (state) => state.auth.user || {}
export const selectAuthProfile = (state) => state.auth.profile || {}

export const selectIsLoggedIn = createCachedSelector(
  [
    (state) => {
      const { accessToken, refreshToken, refreshTokenExpiresAt, user } =
        state.auth
      return !(
        !accessToken ||
        !refreshToken ||
        willExpireIn(refreshTokenExpiresAt, 0) ||
        !user?.id
      )
    },
  ],
  (isLoggedIn) => isLoggedIn,
)((_) => "isLoggedIn")

export const selectIsUserVerified = (state) => state.auth.user?.verified

// utils
const setUserSession = (state, payload) => {
  const {
    user,
    access_token: accessToken,
    access_token_expires_at: expiresAt,
    refresh_token: refreshToken,
    refresh_token_expires_at: refreshTokenExpiresAt,
  } = omitBy(isEmpty)(payload)
  if (!isEmpty(user)) {
    localStorage.setItem("user", JSON.stringify(user))
    state.user = user

    // check if we should update cached userId
    const cachedUserId = JSON.parse(localStorage.getItem("_userId"))
    if (!!user.id && (cachedUserId === null || cachedUserId !== user.id)) {
      localStorage.setItem("_userId", JSON.stringify(user.id))
    }

    // fire identify event for with FE library
    const maybeAnonId = JSON.parse(localStorage.getItem("_anonId"))
    const args = [user?.id, { email: user?.email }]
    if (maybeAnonId)
      args.push({
        anonymousId: maybeAnonId,
      })
  }
  if (accessToken) {
    localStorage.setItem("accessToken", JSON.stringify(accessToken))
    state.accessToken = accessToken
  }
  if (expiresAt) {
    localStorage.setItem("expiresAt", JSON.stringify(expiresAt))
    state.expiresAt = expiresAt
  }
  if (refreshToken) {
    localStorage.setItem("refreshToken", JSON.stringify(refreshToken))
    state.refreshToken = refreshToken
  }
  if (refreshTokenExpiresAt) {
    localStorage.setItem(
      "refreshTokenExpiresAt",
      JSON.stringify(refreshTokenExpiresAt),
    )
    state.refreshTokenExpiresAt = refreshTokenExpiresAt
  }
}
