import { isRejected, isFulfilled } from "@reduxjs/toolkit"
import { notify } from "reapop"
import {
  analyticsIdentify,
  analyticsPage,
  analyticsTrack,
} from "../../../api/analytics"
import {
  checkUsername,
  login,
  logout,
  resetPassword,
  signUp,
  updateUser,
} from "../../../api/auth/auth.slice"
import { deleteList } from "../../../api/lists/lists.slice"
import { getOverride } from "../../../api/overrides/overrides.slice"
import { getDomainVerifyStatus } from "../../../api/listings/listing.slice"
import { sendShareClick } from "../../../api/share_links"

/**
 * Extract messages from async thunks and dispatch notifications.
 */
export const reapopNotificationMiddleware = (store) => (next) => (action) => {
  const isBlacklisted = isRejected(
    getDomainVerifyStatus,
    analyticsTrack,
    analyticsPage,
    analyticsIdentify,
    getOverride,
    logout,
    checkUsername,
    login,
    signUp,
    sendShareClick,
  )

  const isWhitelisted = isFulfilled(deleteList, updateUser, resetPassword)
  if (isWhitelisted(action)) {
    const payload = action.payload

    let message = "Successfully updated!"
    if (payload?.listId) {
      message = "Successfully deleted list!"
    }

    if (message) {
      store.dispatch(successNotification(message))
    }
  }

  if (isBlacklisted(action)) return next(action)

  if (action.type && isRejected(action)) {
    const statusCode = action.error?.code
    const message = action.meta?.rejectedWithValue
      ? action.payload?.message
      : action.error?.message

    if (message && statusCode !== "404") {
      // optimization: using string template here vs invoking
      //  `renderToString` on on each action (see below)
      const maybeCustomError = customErrorMsg(statusCode)
      if (maybeCustomError) {
        store.dispatch(failureNotification(maybeCustomError))
      } else {
        store.dispatch(failureNotification(message))
      }
    }
  }

  return next(action)
}

export default reapopNotificationMiddleware

function customErrorMsg(statusCode) {
  return (
    {
      404: "Page not found",
    }[statusCode] || null
  )
}

const _makeNotification = (html) =>
  notify({
    message: html,
    dismissAfter: 8000,
    dismissible: true,
    allowHTML: true,
  })

export const profileNotification = (message) =>
  _makeNotification(
    `
  <div class="relative flex items-center m-2">
    <svg width="40" height="40" viewBox="0 0 20 20" fill="#366EFF" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M10 2.29167C5.74281 2.29167 2.29167 5.74281 2.29167 10C2.29167 14.2572 5.74281 17.7083 10 17.7083C14.2572 17.7083 17.7083 14.2572 17.7083 10C17.7083 5.74281 14.2572 2.29167 10 2.29167ZM1.04167 10C1.04167 5.05245 5.05245 1.04167 10 1.04167C14.9476 1.04167 18.9583 5.05245 18.9583 10C18.9583 14.9476 14.9476 18.9583 10 18.9583C5.05245 18.9583 1.04167 14.9476 1.04167 10Z" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M6.10425 13.5273C6.67076 12.9986 7.43044 12.7083 8.21429 12.7083H11.7857C12.5696 12.7083 13.3292 12.9986 13.8958 13.5273C14.4637 14.0574 14.7917 14.786 14.7917 15.5556V16.6667C14.7917 17.0118 14.5118 17.2917 14.1667 17.2917C13.8215 17.2917 13.5417 17.0118 13.5417 16.6667V15.5556C13.5417 15.1464 13.3679 14.7445 13.0429 14.4411C12.7163 14.1364 12.2648 13.9583 11.7857 13.9583H8.21429C7.7352 13.9583 7.28367 14.1364 6.95715 14.4411C6.63209 14.7445 6.45834 15.1464 6.45834 15.5556V16.6667C6.45834 17.0118 6.17852 17.2917 5.83334 17.2917C5.48816 17.2917 5.20834 17.0118 5.20834 16.6667V15.5556C5.20834 14.786 5.53629 14.0574 6.10425 13.5273Z" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M10 6.45834C8.96447 6.45834 8.125 7.2978 8.125 8.33334C8.125 9.36887 8.96447 10.2083 10 10.2083C11.0355 10.2083 11.875 9.36887 11.875 8.33334C11.875 7.2978 11.0355 6.45834 10 6.45834ZM6.875 8.33334C6.875 6.60745 8.27412 5.20834 10 5.20834C11.7259 5.20834 13.125 6.60745 13.125 8.33334C13.125 10.0592 11.7259 11.4583 10 11.4583C8.27412 11.4583 6.875 10.0592 6.875 8.33334Z" />
    </svg>
    <span class="text-gray-600 ml-2">${message}</span>
  </div>`,
  )

export const successNotification = (message) =>
  _makeNotification(
    `
      <div class="relative flex items-center m-2">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="#00ff00" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1086 4.55806C17.3527 4.80214 17.3527 5.19786 17.1086 5.44194L7.94194 14.6086C7.69786 14.8527 7.30214 14.8527 7.05806 14.6086L2.89139 10.4419C2.64731 10.1979 2.64731 9.80214 2.89139 9.55806C3.13547 9.31398 3.5312 9.31398 3.77527 9.55806L7.5 13.2828L16.2247 4.55806C16.4688 4.31398 16.8645 4.31398 17.1086 4.55806Z" />
        </svg>
        <span class="text-gray-600 ml-2">${message}</span>
      </div>`,
  )

export const failureNotification = (message) =>
  _makeNotification(
    `
<div class="relative flex items-center m-2">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="-ml-4 w-6 h-6 text-red-300">
      <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"></path>
  </svg>
  <span class="text-gray-600 ml-2">${message}</span>
</div>`,
  )

/**
 * NOTE! Regenerate HTML (paste above)
 * to change styling of error msg toast
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"
import { renderToString } from "react-dom/server"

const html = renderToString(
  <div className="relative flex items-start m-2">
    <ExclamationCircleIcon className="absolute top-1 -left-3 w-6 h-6 text-red-300" />
    <span className="text-gray-600 ml-6">{message}</span>
  </div>
)
 */
