import { useEffect } from "react"
import { setIsInstalled } from "../../../api/preferences/preferences.slice"
import { useDispatch } from "react-redux"

export function useIsInstalled() {
  const dispatch = useDispatch()

  useEffect(() => {
    const mediaQuery = window.matchMedia("(display-mode: standalone)")
    const handler = (event) => dispatch(setIsInstalled(!!event.matches))
    handler(mediaQuery)

    mediaQuery.addEventListener("change", handler)
    return () => mediaQuery.removeEventListener("change", handler)
  }, [dispatch])
}
