import { Dialog, Transition } from "@headlessui/react"
import { Fragment } from "react"
import { classNames } from "../../utils/utils"
import { useSelector } from "react-redux"
import { selectIsMobile } from "../../../api/preferences/preferences.slice"

// Variants
export const VARIANTS = {
  TALL: "TALL",
  FLEX: "FLEX",
}

const MobileModal = ({
  open,
  handleClose,
  children,
  variant = VARIANTS.FLEX,
  scrollable = false,
  classes = {},
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="absolute z-50" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700 bg-opacity-60" />
        </Transition.Child>
        <div className="fixed inset-0 z-40 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom={
              scrollable
                ? "translate-y-[calc(100vh-200px)]"
                : "translate-y-full"
            }
            enterTo="translate-y-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-y-0"
            leaveTo="translate-y-full"
          >
            {/* pointer-events-none here to pass click to close modal overlay */}
            <Dialog.Panel className="flex flex-col w-screen justify-end mt-20 pointer-events-none">
              {/* pointer-events-auto here to stop click passthrough on modal content */}
              <div
                className={classNames(
                  "relative rounded-t-xl p-6 shadow-xl pointer-events-auto overflow-x-clip overflow-y-scroll",
                  {
                    [VARIANTS.TALL]: "max-h-[calc(100dvh-56px)]",
                    [VARIANTS.FLEX]: "max-h-full",
                  }[variant],
                  classes?.container || "bg-white",
                  scrollable ? "h-max" : "mt-auto",
                )}
              >
                <div className="absolute left-0 right-0 top-2.5 m-auto h-1 w-16 rounded-sm bg-gray-400" />
                <div
                  className={classNames(
                    scrollable &&
                      "overflow-y-auto overflow-x-clip scrollbar-thin w-full h-max",
                  )}
                >
                  {children}
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

MobileModal.displayName = "MobileModal"

export { MobileModal }

export const DesktopModal = ({ open, handleClose, children, classes = {} }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[49]" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="mx-auto mt-20 flex min-h-full items-start justify-center py-4 text-center sm:mt-[-20px] sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  "relative my-8 w-fit transform overflow-hidden rounded-lg bg-inherit",
                  classes?.container || "bg-white",
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export const Modal = ({ ...props }) => {
  const isMobile = useSelector(selectIsMobile)
  if (isMobile) {
    return <MobileModal {...props} />
  } else {
    return <DesktopModal {...props} />
  }
}
