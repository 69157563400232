import { useEffect } from "react"
import { throttle } from "lodash/fp"
import { useDispatch } from "react-redux"
import { setIsMobile } from "../../api/preferences/preferences.slice"

/*
NOTE! This hook is called once at the top of the app
to prevent redundant `resize` listeners.

Use `selectIsMobile` to access the value from a component
*/

export const useIsMobile = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const debouncedHandleResize = throttle(500)(() => {
      dispatch(setIsMobile(window.innerWidth <= 640))
    })

    window.addEventListener("resize", debouncedHandleResize)
    debouncedHandleResize()
    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  }, [dispatch])
}
